import { bmiData } from './bmi';
import { covidData } from './covid';
import { crpData } from './crp';
import { ekgAbiData } from './ekgAbi';
import { farbocitData } from './farbocit';
import { fobData } from './fob';
import { glykemiaData } from './glykemia';
import { ostrostData } from './ostrost';
import { oxymeterData } from './oxymeter';
import { prehliadkaData } from './prehliadka';
import { sbsData } from './sbs';
import { tetanusData } from './tetanus';
import { vyplachdata } from './vyplach';
import { zbrojnyData } from './zbrojny';
import { zdravotnyData } from './zdravotny';
import { zsData } from './zs';

export const data = {
  bmi: bmiData,
  covid: covidData,
  crp: crpData,
  ekgAbi: ekgAbiData,
  farbocit: farbocitData,
  fob: fobData,
  glykemia: glykemiaData,
  ostrost: ostrostData,
  oxymeter: oxymeterData,
  prehliadka: prehliadkaData,
  sbs: sbsData,
  tetanus: tetanusData,
  vyplach: vyplachdata,
  zbrojny: zbrojnyData,
  zdravotny: zdravotnyData,
  zs: zsData,
};

export const topBottom = {
  prehliadka: {
    top: 'Základné informácie o preventívnych prehliadkach vykonávaných \
    na ambulanciách všeobecného lekára pre dospelých sú v zákone \
    577/2004 Z.z. – druhá časť, §2 a príloha č.2.',
  },
  ekgAbi: {
    top: 'Všeobecná zdravotná poisťovňa  toto vyšetrenie hradí svojim poistencom 1x za 2 roky pri preventívnej prehliadke u pacientov nad 50 rokov s  minimálne 1 rizikovým faktorom (vysoký tlak, abnormálne EKG, cukrovka, obezita, fajčiari, vysoká hladina celkového cholesterolu, pacienti po srdcovom infarkte a cievnej mozgovej príhode )  a u všetkých poistencov nad 60 rokov.\
  Union– hradí vyšetrenie svojim poistencom 1x za 2 roky pri preventívnej prehliadke u pacientov nad 40 rokov s minimálne 1rizikovým faktorom.\
  Pre poistencov Dôvery  je toto vyšetrenie spoplatnené.',
  },
};
