import { Helmet } from 'react-helmet';
import { BreadCrumbs } from '../components/Global';

export const Ecasenka = () => {
  return (
    <>
      <Helmet>
        <title>eČasenka | M E D I C s.r.o.</title>
      </Helmet>
      <BreadCrumbs page="E-časenka" />
      <div className="container py-4">
        <h5 style={{ textAlign: 'center' }}>
          eČasenka poskytuje všetky potrebné služby ambulancie na jednom mieste. S nami si vybavíte recept, zistíte
          výsledky vyšetrení či sa dokážete zaradiť do poradia v čakárni z domu úplne jednoducho a bez problémov.
        </h5>
      </div>
      <section className="container ">
        <div className="row">
          <div className="my-3 col-md-6 col-12">
            <h5 style={{ textAlign: 'center' }} className="my-3">
              Lekár - MUDr. Blažena Tomková
            </h5>
            <iframe
              style={{ width: '100%' }}
              height="700"
              title="ecasenka"
              src="https://www.ecasenka.sk/ordinacia/medicsro-mudrblazenatomkova"
            />
          </div>
          <div className="my-3 col-md-6 col-12">
            <h5 style={{ textAlign: 'center' }} className="my-3">
              Sestrička - MUDr. Blažena Tomková
            </h5>
            <iframe
              style={{ width: '100%' }}
              height="700"
              title="ecasenka"
              src="https://www.ecasenka.sk/ordinacia/mudrtomkova-sestricka"
            />
          </div>
          <div className="my-3 col-md-6 col-12">
            <h5 style={{ textAlign: 'center' }} className="my-3">
              Lekár - MUDr. Vladimír Tomko
            </h5>
            <iframe
              style={{ width: '100%' }}
              height="700"
              title="ecasenka"
              src="https://www.ecasenka.sk/ordinacia/medicsro-mudrvladimirtomko"
            />
          </div>
          <div className="my-3 col-md-6 col-12">
            <h5 style={{ textAlign: 'center' }} className="my-3">
              Sestrička - MUDr. Vladimír Tomko
            </h5>
            <iframe
              style={{ width: '100%' }}
              height="700"
              title="ecasenka"
              src="https://www.ecasenka.sk/ordinacia/medicsro-mudrvladimirtomko-sestra"
            />
          </div>
        </div>
      </section>
    </>
  );
};
