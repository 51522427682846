export const sbsData = [
  {
    question: 'Čo to znamená?',
    answer:
      "Na vystavenie zdravotnej spôsobilosti  poskytovať SBS službu je potrebné lekárske vyšetrenie. <br/> Vykonáva sa v súlade  s požiadavkami určenými v zákone 437/2005 Z.z. v §15 a vo vyhláške MZ SR č. 33/2006 v §1-4 a v jej prílohe č.1, č.2\
        Podľa zákona 437/2005 Z.z. v §15<br/><br/> \
        <ul>\
        <li style='list-style:disc'>zdravotná spôsobilosť je - telesná spôsobilosť a psychická spôsobilosť, ktorého súčasťou je aj psychologické vyšetrenie</li>\
        <li style='list-style:disc'> posúdeniu zdravotnej spôsobilosti je osoba povinná sa podrobiť každé 2 roky.</li>\
        </ul><br/><br/>\
       Vystavenie Posudku na poskytovanie služieb v oblasti SBS je spoplatnený podľa aktuálneho platného cenníka u zmluvného všeobecného lekára pre dospelých.",
  },
  {
    question: 'Čo potrebujem absolvovať?',
    answer:
      'Poskytovateľ SBS musi požiadať všeobecného lekára o vyšetrenie zamestnanca a doložit k tomu špeciálne tlačivo-kompletne vyplnené aj s pečiatkou.<br/><br/> \
     Následne sa absolvuje lekárska prehliadka, pri ktorej sa zisťujú hlavne kontraindikácie, ktoré neumožňujú vykonávanie činnosti súvisiacich s poskytovaním služieb v oblasti SBS podľa vyhlášky.<br/><br/>\
    Lekár pošle žiadateľa k psychológovi na vyšetrenie psychickej spôsobilosti. Kontrolné psychologické vyšetrenie sa vyžaduje, iba ak tak určí posudzujúci lekár (pri určitých ochoreniach, alebo zmene zdravotného stavu) <br/><br/>\
        Pri určitých ochoreniach a liečbe lekár pošle žiadateľa aj k lekárovi špecialistovi<br/><br/>\
    Výsledok lekárskej prehliadky je uvedený v lekárskom posudku a to podľa prílohy č. 2 - je spôsobilý / je nespôsobilý',
  },
];
