import { Helmet } from 'react-helmet';
import { BreadCrumbs } from '../components/Global';

export const ZdravotnaStarostlivost = ({ routes }: any) => {
  return (
    <>
      <Helmet>
        <title>Zdravotná starostlivosť | M E D I C s.r.o.</title>
      </Helmet>
      <BreadCrumbs page="Zdravotná starostlivosť" />
      <section className="faq-area section">
        <div className="container">
          <div className="row faq-wrap">
            <div className="col-lg-12">
              <div className="faq-item">
                <ul className="accordion">
                  {routes.map((item: any, index: any) => (
                    <li key={index} className="wow fadeInUp" data-wow-delay=".3s">
                      <a href={'/' + item.path}>{item.title}</a>
                      <p style={{ display: 'none' }}></p>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
