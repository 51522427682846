import { getAuth, signOut } from 'firebase/auth';
import { getDatabase, onValue, ref, set } from 'firebase/database';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { BreadCrumbs } from '../components/Global';
import { logoutUser } from '../store/actions';
import { selectUser } from '../store/selectors';

export const Administracia = () => {
  const user = useSelector(selectUser);
  const currentUser = user.email.split('@')[0];
  const dispatch = useDispatch();
  const db = getDatabase();
  const [loading, setLoading] = useState({
    dovolenka: false,
    posudkovakomisia: false,
    zastupovanie: false,
    ineAktuality: false,
  });
  useEffect(() => {
    if (!user) {
      window.location.href = '/admin';
    }
  }, [user]);

  const initalDovolenka = {
    days: {
      pondelok: '',
      utorok: '',
      streda: '',
      stvrtok: '',
      piatok: '',
    },
    person: '',
    date: '',
    address: '',
    phone: '',
  };

  const initalZastupovanie = {
    date: '',
    person: '',
  };

  const initialPosudkovaKomisia = {
    date: '',
  };

  const initialIneAktuality = {
    date: '',
  };

  const [dovolenka, setDovolenka] = useState(initalDovolenka);
  const [zastupovanie, setZastupovanie] = useState(initalZastupovanie);
  const [posudkovakomisia, setPosudkovakomisia] = useState(initialPosudkovaKomisia);
  const [ineAktuality, setIneAkutality] = useState(initialIneAktuality);

  useEffect(() => {
    if (user) {
      const db = getDatabase();
      const userRef = ref(db, String(user.email.split('@')[0]));
      onValue(userRef, (snapshot) => {
        const data = snapshot.val();
        console.log(data);
        setDovolenka(data.dovolenka || dovolenka);
        setZastupovanie(data.zastupovanie || zastupovanie);
        setPosudkovakomisia(data.posudkovakomisia || posudkovakomisia);
        setIneAkutality(data.ineAktuality || ineAktuality);
      });
    }
  }, []);

  const saveToFirebase = (path: any) => {
    setLoading({ ...loading, [path]: true });
    switch (path) {
      case 'dovolenka':
        set(ref(db, currentUser + '/dovolenka'), dovolenka);
        break;
      case 'zastupovanie':
        set(ref(db, currentUser + '/zastupovanie'), zastupovanie);
        break;
      case 'posudkovakomisia':
        set(ref(db, currentUser + '/posudkovakomisia'), posudkovakomisia);
        break;
      case 'ineAktuality':
        set(ref(db, currentUser + '/ineAktuality'), ineAktuality);
        break;
      default:
        return;
    }
    setLoading({ ...loading, [path]: false });
  };

  const logout = () => {
    const auth = getAuth();
    signOut(auth).then((data) => {
      dispatch(logoutUser());
      window.location.href = '/admin';
    });
  };

  return (
    <>
      <Helmet>
        <title>
          {`Administrácia ${user.email === 'tomko@medicsro.sk' ? 'Vladimír Tomko' : 'Blažena Tomková'}`} | M E D I C
          s.r.o.
        </title>
      </Helmet>
      <BreadCrumbs
        page={`Administrácia ${user.email === 'tomko@medicsro.sk' ? 'Vladimír Tomko' : 'Blažena Tomková'}`}
      />
      {/* @ts-ignore */}
      <center>
        <button className="btn btn-primary mt-3" onClick={logout}>
          Odhlásiť sa
        </button>
        {/* @ts-ignore */}
      </center>
      <section className="appointment single-page pt-5">
        <div className="container">
          <div className="row">
            <div className=" col-12">
              <div className="appointment-inner mb-4">
                <div className="title">
                  <h3>Dovolenka</h3>
                </div>
                <div className="form">
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-12">
                      <div className="form-group">
                        <input
                          style={{ textTransform: 'none' }}
                          value={dovolenka.date}
                          onChange={({ target }: any) => setDovolenka({ ...dovolenka, date: target.value })}
                          type="text"
                          placeholder="Termín"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-12">
                      <div className="form-group">
                        <input
                          value={dovolenka.person}
                          style={{ textTransform: 'none' }}
                          onChange={({ target }: any) => setDovolenka({ ...dovolenka, person: target.value })}
                          type="text"
                          placeholder="Zastupuje"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-12">
                      <div className="form-group">
                        <input
                          value={dovolenka.address}
                          style={{ textTransform: 'none' }}
                          onChange={({ target }: any) => setDovolenka({ ...dovolenka, address: target.value })}
                          type="text"
                          placeholder="Adresa"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-12">
                      <div className="form-group">
                        <input
                          value={dovolenka.phone}
                          style={{ textTransform: 'none' }}
                          onChange={({ target }: any) => setDovolenka({ ...dovolenka, phone: target.value })}
                          type="text"
                          placeholder="Telefón"
                        />
                      </div>
                    </div>
                    {/* Ordinačné hodiny */}
                    <div className="col-12">
                      <div className="form-group">
                        <input
                          style={{ textTransform: 'none' }}
                          value={dovolenka.days.pondelok}
                          onChange={({ target }: any) =>
                            setDovolenka({ ...dovolenka, days: { ...dovolenka.days, [target.name]: target.value } })
                          }
                          name="pondelok"
                          type="text"
                          placeholder="Pondelok"
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <input
                          style={{ textTransform: 'none' }}
                          value={dovolenka.days.utorok}
                          onChange={({ target }: any) =>
                            setDovolenka({ ...dovolenka, days: { ...dovolenka.days, [target.name]: target.value } })
                          }
                          name="utorok"
                          type="text"
                          placeholder="Utorok"
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <input
                          style={{ textTransform: 'none' }}
                          value={dovolenka.days.streda}
                          onChange={({ target }: any) =>
                            setDovolenka({ ...dovolenka, days: { ...dovolenka.days, [target.name]: target.value } })
                          }
                          name="streda"
                          type="text"
                          placeholder="Streda"
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <input
                          style={{ textTransform: 'none' }}
                          value={dovolenka.days.stvrtok}
                          onChange={({ target }: any) =>
                            setDovolenka({ ...dovolenka, days: { ...dovolenka.days, [target.name]: target.value } })
                          }
                          name="stvrtok"
                          type="text"
                          placeholder="Štvrtok"
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <input
                          style={{ textTransform: 'none' }}
                          value={dovolenka.days.piatok}
                          onChange={({ target }: any) =>
                            setDovolenka({ ...dovolenka, days: { ...dovolenka.days, [target.name]: target.value } })
                          }
                          name="piatok"
                          type="text"
                          placeholder="Piatok"
                        />
                      </div>
                    </div>
                  </div>
                  {/* Ordinačné hodiny */}
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group">
                        <div className="button">
                          <button
                            disabled={loading.dovolenka}
                            onClick={() => {
                              saveToFirebase('dovolenka');
                            }}
                            className="btn"
                          >
                            {loading.dovolenka ? 'Načítavam' : 'Uložiť'}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group">
                        <div className="button">
                          <button
                            disabled={loading.dovolenka}
                            onClick={() => {
                              setDovolenka(initalDovolenka);
                              set(ref(db, currentUser + '/dovolenka'), initalDovolenka);
                            }}
                            className="btn"
                          >
                            {loading.dovolenka ? 'Načítavam' : 'Resetovať'}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="appointment-inner my-4">
                <div className="title">
                  <h3>Zastupovanie</h3>
                </div>
                <div className="form">
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-12">
                      <div className="form-group">
                        <input
                          style={{ textTransform: 'none' }}
                          value={zastupovanie.date}
                          onChange={({ target }: any) =>
                            setZastupovanie({
                              ...zastupovanie,
                              date: target.value,
                            })
                          }
                          type="text"
                          placeholder="Termín"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-12">
                      <div className="form-group">
                        <input
                          value={zastupovanie.person}
                          style={{ textTransform: 'none' }}
                          onChange={({ target }: any) =>
                            setZastupovanie({
                              ...zastupovanie,
                              person: target.value,
                            })
                          }
                          type="text"
                          placeholder="Zastupuje"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12">
                      <div className="form-group">
                        <div className="button">
                          <button
                            disabled={loading.zastupovanie}
                            onClick={() => {
                              saveToFirebase('zastupovanie');
                            }}
                            className="btn"
                          >
                            {loading.zastupovanie ? 'Načítavam' : 'Uložiť'}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12">
                      <div className="form-group">
                        <div className="button">
                          <button
                            disabled={loading.zastupovanie}
                            onClick={() => {
                              setZastupovanie(initalZastupovanie);
                              set(ref(db, currentUser + '/zastupovanie'), initalZastupovanie);
                            }}
                            className="btn"
                          >
                            {loading.dovolenka ? 'Načítavam' : 'Resetovať'}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="appointment-inner my-4">
                <div className="title">
                  <h3>Posudková komisia</h3>
                </div>
                <div className="form">
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group">
                        <input
                          style={{ textTransform: 'none' }}
                          value={posudkovakomisia.date}
                          onChange={({ target }: any) =>
                            setPosudkovakomisia({
                              ...posudkovakomisia,
                              date: target.value,
                            })
                          }
                          type="text"
                          placeholder="Termín"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12">
                      <div className="form-group">
                        <div className="button">
                          <button
                            disabled={loading.posudkovakomisia}
                            onClick={() => {
                              saveToFirebase('posudkovakomisia');
                            }}
                            className="btn"
                          >
                            {loading.posudkovakomisia ? 'Načítavam' : 'Uložiť'}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group">
                        <div className="button">
                          <button
                            disabled={loading.posudkovakomisia}
                            onClick={() => {
                              setPosudkovakomisia(initialPosudkovaKomisia);
                              set(ref(db, currentUser + '/posudkovakomisia'), initialPosudkovaKomisia);
                            }}
                            className="btn"
                          >
                            {loading.dovolenka ? 'Načítavam' : 'Resetovať'}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="appointment-inner my-4">
                <div className="title">
                  <h3>Iné</h3>
                </div>
                <div className="form">
                  <div className="row">
                    <div className=" col-12">
                      <div className="form-group">
                        <input
                          style={{ textTransform: 'none' }}
                          value={ineAktuality.date}
                          onChange={({ target }: any) =>
                            setIneAkutality({
                              ...ineAktuality,
                              date: target.value,
                            })
                          }
                          type="text"
                          placeholder="Text"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12">
                      <div className="form-group">
                        <div className="button">
                          <button
                            disabled={loading.ineAktuality}
                            onClick={() => {
                              saveToFirebase('ineAktuality');
                            }}
                            className="btn"
                          >
                            {loading.ineAktuality ? 'Načítavam' : 'Uložiť'}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group">
                        <div className="button">
                          <button
                            disabled={loading.ineAktuality}
                            onClick={() => {
                              setIneAkutality(initialIneAktuality);
                              set(ref(db, currentUser + '/ineAktuality'), initialIneAktuality);
                            }}
                            className="btn"
                          >
                            {loading.dovolenka ? 'Načítavam' : 'Resetovať'}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
