import { useState } from 'react';

export const Navigation = () => {
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  return (
    <header className="header">
      <div className="header-inner">
        <div className="container">
          <div className="inner">
            <div className="row">
              <div className="col-lg-3 col-md-3 col-12">
                <div className="logo">
                  <a href="/">
                    <img src="img/logo.png" alt="logo" />
                  </a>
                </div>

                <div className="mobile-nav">
                  <div className="slicknav_menu">
                    <a
                      href="#"
                      aria-haspopup="true"
                      role="button"
                      tabIndex={0}
                      className="slicknav_btn slicknav_collapsed"
                      style={{ outline: 'none' }}
                    >
                      <span className="slicknav_menutxt"></span>
                      <span
                        className="slicknav_icon slicknav_no-text"
                        onClick={() => setShowMobileMenu(!showMobileMenu)}
                      >
                        <span className="slicknav_icon-bar"></span>
                        <span className="slicknav_icon-bar"></span>
                        <span className="slicknav_icon-bar"></span>
                      </span>
                    </a>
                    <ul
                      className="slicknav_nav slicknav_hidden"
                      aria-hidden="true"
                      style={{
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: showMobileMenu ? 'flex' : 'none',
                      }}
                      role="menu"
                    >
                      <li className="active slicknav_collapsed slicknav_parent">
                        <a href="#" tabIndex={-1}>
                          Domov
                        </a>
                      </li>
                      <li>
                        <a href="/aktuality-tomkova">Aktuality MUDr. Tomková</a>
                      </li>
                      <li>
                        <a href="/aktuality-tomko">Aktuality MUDr. Tomko</a>
                      </li>
                      <li>
                        <a href="/personal">Personál</a>
                      </li>
                      <li>
                        <a href="/obvod">Obvod</a>
                      </li>
                      <li>
                        <a href="/cennik">Cenník</a>
                      </li>
                      <li>
                        <a href="/formulare">Formuláre</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-7 col-md-9 col-12">
                <div className="main-menu">
                  <nav className="navigation">
                    <ul className="nav menu">
                      <li className={window.location.pathname === '/' ? 'active' : ''}>
                        <a href="/">Domov</a>
                      </li>
                      <li
                        className={
                          window.location.pathname === '/aktuality-tomko' ||
                          window.location.pathname === '/aktuality-tomkova'
                            ? 'active'
                            : ''
                        }
                      >
                        <a href="#">
                          Aktuality <i className="icofont-rounded-down"></i>
                        </a>
                        <ul className="dropdown">
                          <li>
                            <a href="/aktuality-tomkova">MUDr. Blažena Tomková</a>
                          </li>
                          <li>
                            <a href="/aktuality-tomko">MUDr. Vladimír Tomko</a>
                          </li>
                        </ul>
                      </li>
                      <li className={window.location.pathname === '/personal' ? 'active' : ''}>
                        <a href="/personal">Personál</a>
                      </li>
                      <li
                        className={
                          window.location.pathname === '/obvod-tomko' || window.location.pathname === '/obvod-tomkova'
                            ? 'active'
                            : ''
                        }
                      >
                        <a href="/obvod">Obvod</a>
                      </li>

                      <li className={window.location.pathname === '/cennik' ? 'active' : ''}>
                        <a href="/cennik">Cenník</a>
                      </li>
                      <li className={window.location.pathname === '/formulare' ? 'active' : ''}>
                        <a href="/formulare">Formuláre</a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
              <div className="col-lg-2 col-12">
                <div className="get-quote">
                  <a href="/ecasenka" className="btn">
                    E-ČASENKA
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
