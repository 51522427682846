export const oxymeterData = [
  {
    question: "Čo je to oxymeter?",
    answer:
      "Pulzný oxymeter je jednoduchý prístroj, ktorý meria jednoduchým, spoľahlivým a neinvazívnym spôsobom saturáciu krvi kyslíkom a tepovú frekvenciu priložením najčastejšie na bruško prsta. ",
  },
  {
    question: "Aký má význam?",
    answer:
      "Posúdenie okysličenia krvi je významné napr. u pacientov s ochorením dýchacích ciest, srdca, dušnosťou. Normálna hodnota okysličenia krvi kyslíkom je nad 92%.  ",
  },
];
