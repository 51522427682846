import { LOGIN_SUCCESS, LOGOUT } from "../types";

export const loginUser = (user: any) => {
  return {
    type: LOGIN_SUCCESS,
    user,
  };
};

export const logoutUser = () => {
  return {
    type: LOGOUT,
  };
};
