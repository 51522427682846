import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { BreadCrumbs } from '../components/Global';

export const Formulare = () => {
  const history = useLocation();
  const formulare = [
    {
      path: '/forms/Lekarsky_nalez_na_ucely_konania-vo-veciach-kompenzacie.docx',
      title: 'Lekársky nález na účely konania vo veciach kompenzácie, preukazu a parkovacieho preukazu',
    },
    {
      path: '/forms/Zdravotny-preukaz-pracovnika-–-poucenie.docx',
      title: 'Zdravotný preukaz pracovníka – poučenie',
    },
    {
      path: '/forms/Lekarsky_nalez_na_ucely_posudenia_odkazanosti_na_socialnu_sluzbu_2014.docx',
      title: 'Lekársky nález na účely posúdenia odkázanosti na sociálnu službu',
    },
    {
      path: '/forms/IS_ockovanie proti chrípke.pdf',
      title: 'Poučenie a informovaný súhlas pacienta - očkovanie proti chrípke',
    },
    {
      path: '/forms/IS_ockovanie proti tetanu a zaskrtu-5.pdf',
      title: 'Poučenie a informovaný súhlas pacienta - očkovanie proti tetanu a záškrtu',
    },
    {
      path: '/forms/220510 Ochrana osobných údajov_informačná povinnosť voči pacientom.pdf',
      title: 'Zásady ochrany osobných údajov',
    },
    {
      path: '/forms/Potvrdenie o sprístupnení ZD pacientovi_zákonnému zástupcovi.pdf',
      title: 'Potvrdenie o sprístupnení ZD pacientovi/zákonnému zástupcovi pacienta',
    },
    {
      path: '/forms/ziadost o poskytovanie informácii telefonicky a emailom-2.pdf',
      title: 'Žiadosť o poskytovanie údajov týkajúcich sa zdravia e-mailom a telefónicky',
    },
    {
      path: 'http://www.infodoktor.sk/wp-content/uploads/2014/02/Pr%C3%ADloha-č.6-vyhl.9_2009-Z.z.-MV-SR.pdf',
      title:
        'Čestné vyhlásenie posudzovanej osoby vo vzťahu k jej zdravotnej spôsobilosti na vedenie motorových vozidiel',
    },
    {
      path: 'http://www.infodoktor.sk/wp-content/uploads/2014/02/Pr%C3%ADloha-č.2-k-vyhláške-č.229_2011-Z.z.-MV-SR.pdf',
      title:
        'Čestné vyhlásenie žiadateľa o vydanie zbrojného preukazu alebo držiteľa zbrojného preukazu vo vzťahu k jeho zdravotnej spôsobilosti na držanie alebo nosenie strelnej zbrane a streliva',
    },
    {
      path: 'http://www.infodoktor.sk/wp-content/uploads/2014/02/Pr%C3%ADloha-č.2-k-vyhláške-č.33_2006-Z.z.-MZ-SR.pdf',
      title: 'Posudok na poskytovanie služieb v oblasti súkromnej bezpečnosti súkromnými bezpečnostnými službami',
    },
  ];
  return (
    <>
      <Helmet>
        <title>Formuláre | M E D I C s.r.o.</title>
      </Helmet>
      <BreadCrumbs page="Formuláre" />
      <section className="faq-area section">
        <div className="container">
          <div className="row faq-wrap">
            <div className="col-lg-12">
              <h4 style={{ textAlign: 'center' }} className="mb-4">
                Pokiaľ potrebujete vyšetrenie a potvrdenie k uvedeným oblastiam, prosím doneste so sebou kompletne
                vyplnený formulár.
              </h4>

              <div className="faq-item">
                <ul className="accordion">
                  {formulare.map((item: any, index: any) => (
                    <li
                      onClick={() => (window.location = item.path)}
                      key={index}
                      className="wow fadeInUp"
                      data-wow-delay=".3s"
                    >
                      <p>{item.title}</p>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
