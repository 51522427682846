export const bmiData = [
  {
    question: 'Čo je to BMI?',
    answer:
      'BMI je anglická skratka pre Body Mass Index, čo v preklade znamená index telesnej hmotnosti. \
    Ide o metódu určenia zdravotného rizika obezity na základe telesnej hmotnosti a výšky osoby. ',
  },
  // {
  //   question: 'Ako sa vypočíta?',
  //   answer: 'Túto sekciu doplníme čoskoro.',
  // },
  {
    question: 'Čo je to WHR index?',
    answer:
      'WHR index (skratka Waist to Hip Ratio) určuje pomer pomer medzi obvodom pásu a obvodom bokov.\
    Tukové tkanivo  vytvára rôzne hormóny a pôsobky, ktoré sú pre správnu funkciu tela potrebné. Lenže telo počíta s tým, že tukového tkaniva bude možno 15 či 20%. Akonáhle je v hre obezita, tak tukové tkanivo produkuje priveľa látok a aj odpadu a tým sa situácia mení. Telo sa stáva náchylnejšie na rôzne zápalové procesy a chronické ochorenia od zhoršenia astmy počnúc.',
  },
  // {
  //   question: 'Ako sa vypočíta?',
  //   answer: 'Túto sekciu doplníme čoskoro.',
  // },
  {
    question: 'Obvod pása',
    answer:
      'Najspoľahlivejším zo spôsobov ako doma merať mieru obezity je \
    obvod pása. \
    Nie je to síce metóda presná na milimetre, ale ak chudnete, tento parameter bude stále klesať. Bez ohľadu na to, čo sa bude diať s váhou, ak vám klesá obvod pásu, tak chudnete. \
    Ak prestanete cvičiť a racionálne sa stravovať, na váhe nemusíte vidieť nijaké zmeny aj niekoľko týždňov. Niekedy aj mesiacov. Zdá sa že všetko je v poriadku. Môžte strácať svalstvo a kostnú hmotu z necvičenia. To je na váhe mínus. Ak ale zároveň po milimetríku priberáte, tak to je na váhe plus. Výsledok je, že váha sa nemení, zato zloženie vášho tela áno.\
    Preto sa vždy zaoberajte meraním brucha, nie len váhou.\
    Merajte vždy rovnakým meradlom a na rovnakom mieste. Výhodné býva zhruba miesto, kadiaľ vedie opasok, čiže asi 2 až 4 centimetre pod pupkom. Alebo jednoducho tam, kde je brucho najväčšie. “Oficiálne” sa má merať v strede oblasti medzi rebrami a vrchom panvovej kosti.',
  },
];
