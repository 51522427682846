export const zbrojnyData = [
  {
    question: 'Čo je zbrojný preukaz?',
    answer:
      "Zbrojný preukaz je verejná listina, ktorá fyzickú osobu oprávňuje držať alebo nosiť zbraň a strelivo v rozsahu ustanovenom pre jednotlivé skupiny zbrojného preukazu. Zbrojný preukaz sa rozdeľuje podľa účelu používania zbrane alebo streliva a podľa rozsahu oprávnenia nosiť alebo držať zbraň do skupín:<br/><br/>\
      <ul>\
        <li style='list-style:disc'>A nosenie zbrane a streliva na ochranu osoby a majetku,</li>\
        <li style='list-style:disc'>B držanie zbrane a streliva na ochranu osoby a majetku,>/li>\
        <li style='list-style:disc'>C držanie zbrane a streliva na výkon zamestnania alebo oprávnenia podľa osobitného predpisu,</li>\
        <li style='list-style:disc'>D držanie zbrane a streliva na poľovné účely,</li>\
        <li style='list-style:disc'>E držanie zbrane a streliva na športové účely,</li>\
        <li style='list-style:disc'>F držanie zbrane a streliva na múzejné alebo zberateľské účely.</li>\
         </ul>",
  },
  {
    question: 'Čo to znamená?',
    answer:
      'Na vystavenie potvrdenia potrebuje doložiť doklad o psychickej a zdravotnej spôsobilosti na držanie alebo nosenie zbrane a streliva.<br/><br/>\
    Postup je určerný vo Vyhláške č. 229/2011 Z. z. o postupe pri posudzovaní zdravotnej spôsobilosti a psychickej spôsobilosti na držanie alebo nosenie strelných zbraní a streliva a o náležitostiach lekárskeho posudku a psychologického posudku.<br/><br/>\
    Psychická spôsobilosť žiadateľa sa preukazuje psychologickým posudkom, ktorý vydávajú na základe psychologického vyšetrenia klinickí psychológovia.<br/><br/>\
    Zdravotná spôsobilosť sa preukazuje na základe výsledku lekárskej prehliadky, ktorú vykonáva všeobecný lekár pre dospelých alebo lekár so špecializáciou v odbore pediatria pre deti a dorast, sktorým ma žiadateľ uzatvorenú dhodu o poskytovaní zdravotnej starostlivosti.<br/><br/>\
    Posudzuje sa lekárskou prehliadkou, pričom náklady s tým spojené uhrádza ten, koho zdravotná spôsobilosť sa posudzuje.<br/><br/>\
    Doklady nesmú byť v čase podania žiadosti staršie ako tri mesiace. Policajný útvar vydá zbrojný preukaz s platnosťou max. na desať rokov',
  },
  {
    question: 'Čo potrebujem absolvovať?',
    answer:
      'Pred lekárskou prehliadkou je najprv potrebné vyplniť čestné vyhlásenie o svojom zdravotnom stave- viď vo formulároch.<br/><br/>\
    Cieľom lekárskej prehliadky je  posúdenie zdravotného stavu a  splnenia  minimálnych požiadaviek so zameraním na choroby a poruchy, ktoré zdravotnú spôsobilosť na držanie zbrane a streliva vylučujú alebo podmieňujú.<br/><br/>\
    Vo vyhláške sú presne určené minimálne zdravotné požiadavky na držanie zbrane a streliva podľa jednotlivých ochorení. Vzhľadom na Vašu diagnozu a liečbu môžte byť odoslaní k príslušnému špecialistovi.<br/><br/>\
    Pri viacerých ochoreniach je spôsobilosť  podmienená pravidelnými lekárskymi prehliadkami u špecialistov, pravidelnou liečbou a odbornými kontrolami!!',
  },
  {
    question: 'Aký je postup?',
    answer:
      "<ul>\
    <li style='list-style:disc'>Na začiatku je potrebné od svojho  všeobecného lekára  potvrdiť tlačivo Výpis zo všeobecnej zdravotnej dokumentácie. To je potrebné doložiť psychologovi pred absolvovaním vyšetrenia a testov</li>\
    <li style='list-style:disc'>Následne sa bude treba objednať ku klinickému psychológovi. Po úspešnom zvládnutí psychotestov dostanete kartičku psychickej spôsobilosti.</li>\
    <li style='list-style:disc'>Po klinickom psychológovi je potrebné sa opäť vrátiť k všeobecnému lekárovi s kartičkou od psychológa a  tam absolvovať lekárske vyšetrenie so zameraním na zdravotnú spôsobilost držať zbran. Po úspešnom vyšetrení lekár následne vydá posudok o zdravotnej spôsobilosti.</li>\
    <li style='list-style:disc'>So všetkými dokladmi je potrebné ísť na políciu.</li>\
     </ul>",
  },
];
