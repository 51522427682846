import { Reducer } from "redux";
import { LOGIN_SUCCESS, LOGOUT } from "../types";

export const authReducer: Reducer<any, any> = (
  state = { loggedin: false, user: null },
  action: any
) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return { ...state, loggedin: true, user: action.user };
    case LOGOUT:
      return { ...state, loggedin: false, user: null };
    default:
      return state;
  }
};
