export const Team = () => {
  const personal = {
    tomko: [
      { name: 'MUDr. Blažena Tomková', position: 'Lekár' },
      { name: 'MUDr. Vladimír Tomko', position: 'Lekár' },
    ],
    tomkova: [
      { name: 'Bc. Martina Gehová', position: 'Sestra - MUDr. Tomková' },
      { name: 'Veronika Duszová', position: 'Sestra - MUDr. Tomková' },
      { name: 'Katarína Slivenská', position: 'Sestra - MUDr. Tomková' },
      { name: 'Hajnalka Szentandrasiová', position: 'Sestra - MUDr. Tomko' },
      { name: 'Zuzana Duszová', position: 'Sestra - MUDr. Tomko' },
    ],
  };
  return (
    <section id="team" className="team section single-page">
      <div className="container">
        <h3>Lekári</h3>
        <div className="row">
          {personal.tomko.map((item) => (
            <div className="col-lg-4 col-md-6 col-12">
              <div className="single-team">
                <div className="t-head">
                  {/* <img src="https://via.placeholder.com/560x575" alt="#" /> */}
                  <div className="t-icon">
                    <a href="appointment.html" className="btn">
                      Get Appointment
                    </a>
                  </div>
                </div>
                <div className="t-bottom">
                  <p>{item.position}</p>
                  <h2>
                    <a href="#">{item.name}</a>
                  </h2>
                </div>
              </div>
            </div>
          ))}
        </div>
        <h3 className="mt-5">Zdravotné sestry</h3>
        <div className="row">
          {personal.tomkova.map((item) => (
            <div className="col-lg-4 col-md-6 col-12">
              <div className="single-team">
                <div className="t-head">
                  {/* <img src="https://via.placeholder.com/560x575" alt="#" /> */}
                  <div className="t-icon">
                    <a href="appointment.html" className="btn">
                      Get Appointment
                    </a>
                  </div>
                </div>
                <div className="t-bottom">
                  <p>{item.position}</p>
                  <h2>
                    <a href="#">{item.name}</a>
                  </h2>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
