export const Slider = () => {
  return (
    <section className="slider">
      <div className="hero-slider">
        <div
          className="single-slider"
          style={{
            backgroundImage: "url('img/slider.jpg')",
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-7">
                <div className="text">
                  <h1>
                    Poskytujeme <span>lekárske</span> služby, ktorým môžete <span>dôverovať!</span>
                  </h1>
                  <h2 className="mt-4">Ambulancia všeobecných lekárov pre dospelých</h2>
                  <div className="button">
                    <a href="/aktuality-tomkova" className="btn">
                      Aktuality - MUDr. Tomková
                    </a>
                    <a href="/aktuality-tomko" className="btn">
                      Aktuality - MUDr. Tomko
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
