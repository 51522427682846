/* eslint-disable no-multi-str */
export const covidData = [
  {
    question: 'Čo je to COVID-19?',
    answer:
      'COVID-19 je infekčné ochorenie, vyvolané koronavírusom SARS-CoV-2. Prvýkrát bol identifikovaný u pacientov so závažným respiračným ochorením v decembri roku 2019 v čínskom meste Wu-chan. COVID-19 postihuje najmä dýchací systém, v ťažkých prípadoch vyvoláva ťažký zápal pľúc a môže viesť až k úmrtiu pacienta.<br><br>\
        Vírus sa prenáša kvapôčkami sekrétu pri kašli, kýchaní a rozprávaní. Ohrozuje osoby, ktoré sú v blízkom alebo dlhšie trvajúcom kontakte s nakazeným. Infekcia sa prenáša aj cez kontaminované predmety<br><br>\
    Inkubačný čas ochorenia COVID-19 je 2 až 14 dní<br><br>\
    Za najviac infekčnú sa považuje osoba s prítomnými klinickými príznakmi. Osoba je infekčná už pred nástupom prvých príznakov. ',
  },
  {
    question: 'Aktuálne info',
    answer:
      "<ul>\
    <li style='list-style:disc'>https://korona.gov.sk/ </li>\
    <li style='list-style:disc'>https://korona.gov.sk/co-mam-robit-ak-som-bol-v-uzkom-kontakte-s-covid/</li>\
    <li style='list-style:disc'>https://korona.gov.sk/povinna-domaca-karantena/ </li>\
    </ul>",
  },
  // {
  //   question: 'Prevencia',
  //   answer: 'Túto sekciu doplníme čoskoro',
  // },
  // {
  //   question: 'Príznaky',
  //   answer: 'Túto sekciu doplníme čoskoro',
  // },
  // {
  //   question: 'Liečba',
  //   answer: 'Túto sekciu doplníme čoskoro',
  // },
  // {
  //   question: 'Ako je to s karanténou',
  //   answer: 'Túto sekciu doplníme čoskoro',
  // },
  // {
  //   question: 'Ako sa správať v domácnosti s infikovaným covid-19',
  //   answer: 'Túto sekciu doplníme čoskoro',
  // },
  {
    question: 'Link na brožúru',
    answer:
      "<a href='https://ssvpl.sk/brozura-ako-zvladnut-covid-19/?utm_source=%27newsletter%27&utm_medium=%27email%27&utm_campaign=%27Brožúra%3A+Ako+zvládnuť+COVID-19%3F%27&sender_ctype=email&sender_campaign=eEG2Ak&sender_customer=6804wRQ'>Na tomto odkaze sa nachádza link na COVID brožúru</a>",
  },
];
