import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Footer, Navigation } from './components/Global';
import {
  Admin,
  Administracia,
  AktualityTomko,
  AktualityTomkova,
  Ecasenka,
  Error404,
  Home,
  Lieky,
  Obvod,
  Personal,
  Pricing,
  OrdinacnyCas,
  DotaznikSpokojnosti,
  Poistovne,
  OsobneUdaje,
  PravaPacientov,
  DoleziteKontakty,
  Cookies,
  Service,
  ZdravotnaStarostlivost,
  Formulare,
} from './pages';
import GoogleFontLoader from 'react-google-font-loader';
import './firebase';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from './store';
import { data, topBottom } from './constants';
import { RemoveTrailingSlash } from './components/Global/RemoveTrailingSlash';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const serviceRoutes: any = [
  { path: 'covid', title: 'COVID-19', faqData: data.covid },
  {
    path: 'preventivna-prehliadka',
    title: 'Preventívna prehliadka',
    faqData: data.prehliadka,
    top: topBottom.prehliadka.top,
  },
  {
    path: 'ekg-abi',
    title: 'EKG a ABI',
    faqData: data.ekgAbi,
    top: topBottom.ekgAbi.top,
  },
  {
    path: 'tetanus',
    title: 'Preočkovanie proti tetanu',
    faqData: data.tetanus,
  },
  {
    path: 'crp',
    title: 'CRP',
    faqData: data.crp,
  },
  {
    path: 'oxymeter',
    title: 'Pulzný oxymeter',
    faqData: data.oxymeter,
  },
  {
    path: 'glykemia',
    title: 'Glykémia',
    faqData: data.glykemia,
  },
  {
    path: 'fob',
    title: 'FOB test',
    faqData: data.fob,
  },
  {
    path: 'zdravotna-starostlivost',
    title: 'Zdravotná spôsobilosť na vedenie motorového vozidla',
    faqData: data.zs,
  },
  {
    path: 'vyplach-usi',
    title: 'Výplach uší',
    faqData: data.vyplach,
  },
  {
    path: 'farbocit',
    title: 'Farbocit',
    faqData: data.farbocit,
  },
  {
    path: 'zrakova-ostrost',
    title: 'Zraková ostrosť',
    faqData: data.ostrost,
  },
  {
    path: 'zbrojny-preukaz',
    title: 'Zbrojný preukaz',
    faqData: data.zbrojny,
  },
  {
    path: 'sbs',
    title: 'Spôsobilosť na SBS',
    faqData: data.sbs,
  },
  {
    path: 'zdravotny-preukaz',
    title: 'Zdravotný preukaz',
    faqData: data.zdravotny,
  },
  {
    path: 'bmi-whr-obvod',
    title: 'BMI, WHR a obvod pása',
    faqData: data.bmi,
  },
];

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <GoogleFontLoader
        fonts={[
          {
            font: 'Poppins',
            weights: [
              '200i',
              300,
              '300i',
              400,
              '400i',
              500,
              '500i',
              600,
              '600i',
              700,
              '700i',
              800,
              '800i',
              900,
              '900i',
            ],
          },
        ]}
      />
      <Navigation />
      <BrowserRouter>
        <RemoveTrailingSlash />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="cennik" element={<Pricing />} />
          <Route path="personal" element={<Personal />} />
          <Route path="aktuality-tomko" element={<AktualityTomko />} />
          <Route path="aktuality-tomkova" element={<AktualityTomkova />} />
          <Route path="ecasenka" element={<Ecasenka />} />
          <Route path="obvod" element={<Obvod />} />
          <Route path="dotaznik-spokojnosti" element={<DotaznikSpokojnosti />} />
          <Route path="lieky" element={<Lieky />} />
          <Route path="admin" element={<Admin />} />
          <Route path="administracia" element={<Administracia />} />
          <Route path="ordinacny-cas" element={<OrdinacnyCas />} />
          <Route path="poistovne" element={<Poistovne />} />
          <Route path="ochrana-osobnych-udajov" element={<OsobneUdaje />} />
          <Route path="prava-pacientov" element={<PravaPacientov />} />
          <Route path="dolezite-kontakty" element={<DoleziteKontakty />} />
          <Route path="cookies" element={<Cookies />} />
          <Route path="formulare" element={<Formulare />} />
          <Route path="zdravotna-starostlivost" element={<ZdravotnaStarostlivost routes={serviceRoutes} />} />
          {serviceRoutes.map((i: any) => (
            <Route
              path={i.path}
              element={<Service title={i.title} faqData={i.faqData} top={i.top} bottom={i.bottom} />}
            />
          ))}
          <Route path="*" element={<Error404 />} />
        </Routes>
      </BrowserRouter>
      <Footer />
    </PersistGate>
  </Provider>
);
