import { getDatabase, onValue, ref } from 'firebase/database';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { BreadCrumbs } from '../components/Global';
import { AktualityTab } from '../components/Global/AktualityTab';

export const AktualityTomko = () => {
  const [dovolenka, setDovolenka] = useState({
    heading: 'Dovolenka',
    person: '',
    date: '',
    address: '',
    phone: '',
  });
  const [zastupovanie, setZastupovanie] = useState({
    heading: 'Zastupovanie',
    person: '',
    date: '',
    address: '',
    phone: '',
  });
  const [posudkovakomisia, setPosudkovakomisia] = useState({
    heading: 'Posudková komisia',
    date: '',
    person: '',
    address: '',
    phone: '',
  });
  const [ineAktuality, setIneAkutality] = useState({
    heading: 'Iné',
    date: '',
    person: '',
    address: '',
    phone: '',
  });
  const items = [
    { path: 'dovolenka', state: dovolenka },
    { path: 'zastupovanie', state: zastupovanie },
    { path: 'posudkovakomisia', state: posudkovakomisia },
    { path: 'ineAktuality', state: ineAktuality },
  ];

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const db = getDatabase();
    const userRef = ref(db, 'tomko');
    onValue(userRef, (snapshot) => {
      const data = snapshot.val();
      setDovolenka(data.dovolenka || dovolenka);
      setZastupovanie(data.zastupovanie || zastupovanie);
      setPosudkovakomisia(data.posudkovakomisia || posudkovakomisia);
      setIneAkutality(data.ineAktuality || ineAktuality);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>Aktuality MUDr. Tomko | M E D I C s.r.o.</title>
      </Helmet>
      <BreadCrumbs page="Aktuality - MUDr. Tomko" />
      <section id="team" className="team section single-page">
        <div className="container">
          <div className="row">
            {items.map((item) =>
              loading ? (
                <div className="col-12">
                  <div className="single-team">
                    <div className="t-bottom">
                      <p>Načítavam...</p>
                    </div>
                  </div>
                </div>
              ) : (
                <AktualityTab item={item} />
              )
            )}
          </div>
        </div>
      </section>
    </>
  );
};
