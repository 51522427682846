export const zsData = [
  {
    question: 'Čo to znamená?',
    answer:
      'Zdravotnou spôsobilosťou sa rozumie telesná a duševná schopnosť viesť motorové vozidlo.\
      <br/> Postup je určerný vo Vyhláške Ministerstva vnútra č. 9/2009 Z.Z.\
      <br/> Posudzuje sa lekárskou prehliadkou, pričom náklady s tým spojené uhrádza ten, koho zdravotná spôsobilosť sa posudzuje.\
      <br/> Pred lekárskou prehliadkou je najprv potrebné vyplniť v ambulancii čestné vyhlásenie o svojom zdravotnom stave- vid vo formulároch.\
      ',
  },
  {
    question: 'Čo potrebujem absolvovať?',
    answer:
      'Pred lekárskou prehliadkou je najprv potrebné vyplniť v ambulancii čestné vyhlásenie o svojom zdravotnom stave- vid vo formulároch.\
    <br/> Vo vyhláške sú presne určené minimálne zdravotné požiadavky na vedenie motorového vozdila podla jednotlivých ochorení- vzhľadom na Vašu diagnozu a liečbu ste odoslaní k príslušnému špecialistovi.\
    <br/> Pri viacerých ochoreniach je spôsobilosť  podmienená pravidelnými lekárskymi prehliadkami u špecialistov, pravidelnou liečbou a odbornými kontrolami!!\
    <br/> Cieľom lekárskej prehliadky je  posúdenie zdravotného stavu a  splnenia  minimálnych požiadaviek so zameraním na choroby a poruchy, ktoré zdravotnú spôsobilosť na vedenie motorových vozidiel vylučujú alebo podmieňujú. ',
  },
  {
    question: 'Aké možu byť obmdzenia pri vedení motorového vozidla?',
    answer:
      "Zdravotná spôsobilosť môže byť na základe zdravotného stavu osoby podmienená: \
      <ul> \
      <br/> \
    <li style='list-style:disc'>upoužitím zdravotníckej pomôcky pri vedení motorového vozidla, napr. okuliare, naslúchací aparát</li>\
    <li style='list-style:disc'>možnosťou viesť len technicky upravené vozidlo, napr. modifikovaná spojka alebo brzda</li>\
    <li style='list-style:disc'>pravidelným podrobovaním sa lekárskej prehliadke, v určitom intervale v závislosti od ochorenia a výsledkov</li>\
    <li style='list-style:disc'>inými obmedzeniami podľa záverov lekárskej prehliadky, napr. vedenie vozidla len počas dna, len v určitom okruhu od miesta bydliska</li>\
     </ul>",
  },
  {
    question: 'Kto musí absolvovať pravidelné lekárske prehliadky',
    answer:
      "Pravidelným lekárskym prehliadkam každých päť rokov sú povinní podrobiť sa vodiči, ktorí:\
    <ul> \
    <br/> \
<li style='list-style:disc'>vedú motorové vozidlo skupiny 2-  C, C+E, D, D + E a podskupiny C1, C1 + E, D1, D1 + E</li>\
<li style='list-style:disc'>vedú vozidlo s právom prednostnej jazdy, motorové vozidlo využívané na zasielateľstvo a taxislužbu a na poskytovanie poštových služieb</li>\
 </ul>\
 <br/> Ostatní vodiči sú povinní podrobiť sa pravidelnej lekárskej prehliadke najskôr dva mesiace pred dosiahnutím veku 65 rokov a najneskôr do dvoch mesiacov po dosiahnutí veku 65 rokov a následne   po dosiahnutí veku 65 rokov max. každých   5 rokov - v závislosti od zdravotného stavu, ochorení, diagnozy",
  },
  {
    question: 'Čo ak vediem služobne motorové vozidlo?',
    answer:
      'Posudzovanie zdravotnej spôsobilosti na vedenie motorového vozdila v súvislosti s prácou sa riadi  Vestníkom ministerstva zdravotníctva SR z r. 2016 - osobitným odborným usmernením pre práce,  pri ktorých zdravotnú spôsobilosť vyžadujú osobitné predpisy.\
    Podľa toho je u posudzovanej osoby okrem základnej prehliadky u všeobecného lekára pre dospelých potrebné absolvovať aj vyšetrenie u odborných lekárov- vyšetrenie perimetru na očnej ambulancii, vyšetrenia audiogramu na orl, psychologické vyšetrenie, ekg, odber krvi.',
  },
];
