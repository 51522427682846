import { Helmet } from "react-helmet";
import { BreadCrumbs } from "../components/Global";

export const DoleziteKontakty = () => {
  return (
    <>
      <Helmet>
        <title>Dôležité kontakty | M E D I C s.r.o.</title>
      </Helmet>
      <BreadCrumbs page="Dôležité kontakty" />
      <div className="container py-5">
        <div className="row justify-content-center align-items-center">
          <h1>Pomooooc!</h1>
          <p style={{ textAlign: "center" }} className="my-2">
            Nepríjemnosti sa môžu prihodiť. Stala sa v čakárni nehoda? Ide o
            život? Hneď privolajte lekára (áno, hoci aj búchaním na dvere
            ambulancie). V prípade potreby neváhajte poskytnúť postihnutému prvú
            pomoc.
          </p>
          <span className="btn btn-primary col-5 m-1">
            112 - Tiesňové volanie
          </span>
          <span className="btn btn-primary col-5 m-1">155 - Záchranári</span>
          <span className="btn btn-primary col-5 m-1">158 - Polícia</span>
          <span className="btn btn-primary col-5 m-1">150 - Hasiči</span>
        </div>
        <h1 style={{ textAlign: "center" }} className="mt-3">
          KPR
        </h1>
        <ul>
          <li style={{ listStyle: "disc" }}>
            30 stlačení hrudníka (frekvencia 100 stlačei za minútu)
          </li>
          <li style={{ listStyle: "disc" }}>2 vdychy</li>
          <li style={{ listStyle: "disc" }}>30 stlačení hrudníka</li>
          <li style={{ listStyle: "disc" }}>2 vdychy</li>
        </ul>
      </div>
    </>
  );
};
