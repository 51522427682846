import { useEffect, useState } from 'react';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { loginUser } from '../store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from '../store/selectors';
import { Helmet } from 'react-helmet';

export const Admin = () => {
  const [formValues, setFormValues] = useState({ email: '', password: '' });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>({});
  const dispatch = useDispatch();
  const user = useSelector(selectUser);

  useEffect(() => {
    if (user) {
      window.location.href = '/administracia';
    }
  }, [user]);

  const login = () => {
    setError({});
    setLoading(true);
    const auth = getAuth();
    signInWithEmailAndPassword(auth, formValues.email, formValues.password)
      .then((data) => {
        dispatch(loginUser(data.user));
        setLoading(false);
        window.location.href = '/administracia';
      })
      .catch((error) => {
        setLoading(false);
        setError(error);
      });
  };

  document.addEventListener('keypress', function (e) {
    if (e.key === 'Enter') {
      login();
    }
  });

  return (
    <>
      <Helmet>
        <title>Admin | M E D I C s.r.o.</title>
      </Helmet>
      <section className="appointment single-page">
        <div className="container">
          <div className="row">
            <div className=" col-12">
              <div className="appointment-inner">
                <div className="title">
                  <h3>Prihlásenie do administrácie</h3>
                </div>

                <div className="form">
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-12">
                      <div className="form-group">
                        <input
                          style={{ textTransform: 'none' }}
                          name="email"
                          onChange={({ target }: any) =>
                            setFormValues({
                              ...formValues,
                              email: target.value,
                            })
                          }
                          type="text"
                          placeholder="Email"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-12">
                      <div className="form-group">
                        <input
                          style={{ textTransform: 'none' }}
                          name="password"
                          onChange={({ target }: any) =>
                            setFormValues({
                              ...formValues,
                              password: target.value,
                            })
                          }
                          type="password"
                          placeholder="Heslo"
                        />
                      </div>
                    </div>
                  </div>
                  {error && <p style={{ textAlign: 'center', marginBottom: '20px' }}>{error?.code}</p>}
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group">
                        <div className="button">
                          <button disabled={loading} onClick={login} className="btn">
                            {loading ? 'Načítavam' : 'Prihlásiť sa'}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
