export const prehliadkaData = [
  {
    question: "Čo znamená preventívna prehliadka?",
    answer:
      "Je to komplexné vyšetrenie a zhodnotenie zdravotného stavu pacienta.  Vykonáva  ju všeobecný lekár pre dospelých raz za 2 roky. Zameriava sa na prehľad o zdravotnom stave pacienta a možných rizikách,  s dôrazom na odhalenie rizík civilizačných chorôb",
  },
  {
    question: "Čo sa vyšetruje počas preventívnej prehliadky?",
    answer:
      "<ul>\
    <li style='list-style:disc'>Prehliadka začína pohovorom s pacientom- anamnézou- o vyskytujúcich sa ochoreniach v rodine, o prekonaných ochoreniach, operáciach, hospitalizáciách. Upresnia sa alergie, užívané lieky. Overia sa aktuálne zdravotné ťažkosti.  </li>\
    <li style='list-style:disc'>Následne prehliadka zahrna komplexné fyzikálne vyšetrenie pacienta, vyšetrenie pulzu a tlaku krvi, kontrolu hmotnosti, výšky, obvodu pása, zraku, postupné vyšetrenie jednotlivých častí tela - hlavy, krku, hrudníka, brucha, končatin.</li>\
    <li style='list-style:disc'>Podľa dokumentácie sa skontroluje očkovanie, najmä proti tetanu.</li>\
    </ul>",
  },
  {
    question: "Aké laboratórne vyšetrenia sa robia?",
    answer:
      "<ul>\
    <li style='list-style:disc'>laboratórne vyšetrenia moču – chemicky papierikom na ambulancii a mikroskopom v laboratóriu  </li>\
    <li style='list-style:disc'>laboratórne vyšetrenie krvi –  sedimentácia - zápalový parameter (FW), krvný obraz – biele a červené krvinky (leukocyty a erytrocyty), krvné doštičky (trombocyty), hladina cukru (glykémia), obličkový test (kreatinín) a pečeňový test (ALT), u poistenci nad 40 rokov – vyšetrenie tukov-  cholesterolu a triacylglycerolov </li>\
    <li style='list-style:disc'>vyšetrenie stolice na skryté krvácanie (FOB test)</li>\
    </ul>",
  },

  {
    question: "Robia sa aj prístrojové vyšetrenia? ",
    answer:
      "<p>U rizikových pacientvo  nad 4O rokov sa vykonáva</p>\
      <ul>\
          <li style='list-style:disc'>EKG záznam srdcovej činnosti</li>\
          <li style='list-style:disc'>ABI vyšetrenie tuhosti tepien dolných končatín</li>\
          </ul>",
  },
  {
    question:
      "10 najčastejších diagnóz, ktoré môže odhaliť preventívna prehliadka:",
    answer:
      "<p>U rizikových pacientvo  nad 4O rokov sa vykonáva</p>\
      <ul>\
          <li style='list-style:disc'>cukrovka</li>\
          <li style='list-style:disc'>chudokrvnosť</li>\
          <li style='list-style:disc'>vysoký krvný tlak</li>\
          <li style='list-style:disc'>nadváha a obezita</li>\
          <li style='list-style:disc'>porucha funkcie obličiek</li>\
          <li style='list-style:disc'>porucha funkcie pečene</li>\
          <li style='list-style:disc'>zápal v močových cestách</li>\
          <li style='list-style:disc'>porucha srdcového rytmu</li>\
          <li style='list-style:disc'>zvýšená hladina tukov</li>\
          <li style='list-style:disc'>rakovina hrubého čreva</li>\
          </ul>",
  },
  {
    question: "Aký je záver preventívnej prehliadky? ",
    answer:
      "Cieľom je komplexné zhodnotenie všetkych nálezov a vyšetrení. Stanovenie rizikových faktorov konkrétneho pacienta a odporučenie daľšieho postupu-  v diagnostike aj liečbe a najmä preventívnych opatrení, ktoré vie pacient sám pre seba urobiť a tak pozitívne ovplyvniť svoje zdravie aj chorobu. ",
  },
  {
    question: "Preventívna gynekologická prehliadka",
    answer:
      "<ul>\
    <li style='list-style:disc'>ženy od 18 rokov alebo prvého tehotenstva majú nárok na 1 preventívnu prehliadku za rok</li>\
    <li style='list-style:disc'>v materstve – preventívna starostlivosť každý mesiac počas tehotenstva a raz 6 týždňov po pôrode</li>\
    <li style='list-style:disc'>komplexné gynekologické vyšetrenie zamerané na včasnú diagnostiku ochorení ženských pohlavných orgánov</li>\
    <li style='list-style:disc'>skríning rakoviny krčka maternice – ženy vo veku 23 – 64 rokov</li>\
    <li style='list-style:disc'>skríning rakoviny prsníka – ženy vo veku 40 – 69 rokov</li>\
    <li style='list-style:disc'>ultrasonografia prsníkov – raz za dva roky</li>\
     </ul>",
  },
  {
    question: "Urologická preventívna prehliadka",
    answer:
      "<ul>\
    <li style='list-style:disc'>muži nad 50 rokov – raz za 3 roky</li>\
    <li style='list-style:disc'>alebo muži od 40-teho roku, ak sa v ich prvostupňovom príbuzenstve vyskytla rakovina prostaty – raz za 3 roky alebo častejšie</li>\
    <li style='list-style:disc'>vyšetrenie prostaty cez konečník, vyšetrenie semenníkov, ultrazvukové vyšetrenie močových ciest a obličiek, odber krvi na vyšetrenie prevencie karcinómu prostaty/li>\
     </ul>",
  },
];
