import { Helmet } from "react-helmet";
import { BreadCrumbs } from "../components/Global";
import { PricingItems } from "../components/Pricing/PricingItems";

export const Pricing = () => {
  return (
    <>
      <Helmet>
        <title>Cenník | M E D I C s.r.o.</title>
      </Helmet>
      <BreadCrumbs page="Cenník" />
      <PricingItems />
    </>
  );
};
