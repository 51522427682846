export const crpData = [
  {
    question: "Čo je to CRP?",
    answer:
      "CRP- C reaktívny proteín- je proteín akútnej fázy zápalu nachádzajúci sa v krvi. Jeho prirodzená hladina u človeka bez zápalu a nekrózy dosahuje hodnoty 1,0 – 8,0 mg/l. \
    Hladina výraznejšie stúpa pri bakteriálnom zápalovom procese /kdekoľvek v tele/, poškodení tkanív, po operáciách, pri popáleninách a pod. Merateľne hodnoty je možné zaznamenať už o 4 – 6 hodín, maximum dosahuje CRP o 24 – 48 hodín a k poklesu dochádza o 4 – 5 dní. ",
  },
  {
    question: "Hodnoty CRP",
    answer:
      "<table><thead><tr><th>Hodnota CRP [mg/l]</th><th>Význam</th></tr></thead><tbody><tr><td>do 6</td><td>normálna hodnota</td></tr><tr><td>6-35</td><td>vírusová infekcia</td></tr><tr><td>35-50</td><td>neurčité rozmedzie</td></tr><tr><td>viac ako 50</td><td>bakteriálna infekcia</td></tr></tbody></table>",
  },
  {
    question: "Na čo CRP slúži?",
    answer:
      "<div><span>Rýchly test na stanovenie hladiny CRP je pre lekára cenným nástrojom pri </span><br/><br/>\
      <ul>\
    <li style='list-style:disc'>rozlíšovaní bakteriálnej a vírusovej infekcie, čiže je prostriedkom na predchádzanie zbytočnému užívaniu ATB.</li>\
    <li style='list-style:disc'>rozhodovaní sa medzi nutnosťou okamžitej antibakteriálnej terapie a prípadným sledovaním vývoja choroby, vyčkávaním s nasadením antibiotík.</li>\
    <li style='list-style:disc'>slúži ako marker na monitorovanie priebehu ochorenia a účinnosti antibiotickej liečby. </li>\
    <li style='list-style:disc'>tiež je často nevyhnutnou súčasťou pri pátraní po príčine rôznych chorobných stavov /napr. reumatickych, kožných, črevných a pod/</li>\
     </ul><br/>\
     <span>Infekcie dýchacích ciest sú najčastejšími infekčnými ochoreniami, vyskytujúcimi sa v praxi všeobecného lekára  a vo väčšine prípadov je veľmi ťažké rozlíšiť len na základe klinického vyšetrenia, či ide o ochorenie vírusové alebo bakteriálne. Približne 80 % infekcií horných dýchacích ciest vyvolávajú vírusy, nereagujúce na terapiu antibiotikami. Vírusy neliečime antibiotikami,  vtedy nám antibiotiká môžu práveže viac poškodiť a oslabiť našu imunitu.\
     Podobne aj nie každá hnačka je spôsobená bakteriálnou infekciou, ale väčšinou je spôsobená vírusmi, kedy antibiotiká v liečbe nepotrebujeme, lebo by nám mohli hnačku zhoršiť.  </span></div>",
  },
  {
    question: "Prečo škodí časté užívanie antibiotík?",
    answer:
      "<ul>\
    <li style='list-style:disc'>pri nadmernom a nesprávnom užívaní vzniká rezistencia (odolnosť) baktérií voči antibiotikám, ktoré potom neúčinkujú v prípade závažnej bakteriálnej infekcie.</li>\
    <li style='list-style:disc'>rozhodovaní sa medzi nutnosťou okamžitej antibakteriálnej terapie a prípadným sledovaním vývoja choroby, vyčkávaním s nasadením antibiotík.</li>\
    <li style='list-style:disc'>antibiotiká nepôsobia len v mieste zápalu, ale ničia aj tzv. 'dobré baktérie', ktoré sídlia v čreve a zohrávajú dôležitú úlohu v procese trávenia a celkovej imunity tela</li>\
    <li style='list-style:disc'>gynekologická kvasinková infekcia vzniká veľmi často pri a po celkovej liečbe širokospektrálnymi antibiotikami. ATB liečba ničí aj normálne sa vyskytujúce prospešné baktérie, ktorá chráni pred kolonizáciou kvasinkami./</li>\
     </ul>",
  },
];
