import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';

export const Insurance = ({ subpage }: any) => {
  const sliderSettings = {
    dots: false,
    slidesToShow: 3,
    speed: 1000,
    slidesToScroll: 1,
    arrows: false,
    infinite: true,
    autoplay: true,
    autoplayspeed: 500,
  };
  return subpage ? (
    <div className="container py-5">
      <h4 style={{ textAlign: 'center' }} className="mb-5">
        Naša ambulancia má zmluvu s následujúcimi poisťovnňami
      </h4>
      <div className="row">
        <div className="col-lg-12 col-md-12 col-12">
          <Slider {...sliderSettings}>
            <div className="single-clients">
              <img src="img/dovera.png" alt="#" />
              <h5 style={{ textAlign: 'center' }} className="mt-4">
                Dôvera - 24
              </h5>
            </div>
            <div className="single-clients">
              <img src="img/vsp-nobg.png" alt="#" />
              <h5 style={{ textAlign: 'center' }} className="mt-4">
                VŠeobecná zdravotná - 25
              </h5>
            </div>
            <div className="single-clients">
              <img src="img/union.png" alt="#" />
              <h5 style={{ textAlign: 'center' }} className="mt-4">
                Union - 27
              </h5>
            </div>
          </Slider>
        </div>
      </div>
    </div>
  ) : (
    <div className="clients overlay">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-12">
            <Slider {...sliderSettings}>
              <div className="single-clients">
                <img src="img/dovera.png" alt="#" />
              </div>
              <div className="single-clients">
                <img src="img/vsp-nobg.png" alt="#" />
              </div>
              <div className="single-clients">
                <img src="img/union.png" alt="#" />
              </div>
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
};
