import { Helmet } from "react-helmet";
import { BreadCrumbs, Insurance } from "../components/Global";

export const Poistovne = () => {
  return (
    <>
      <Helmet>
        <title>Zmluvné poisťovne | M E D I C s.r.o.</title>
      </Helmet>
      <BreadCrumbs page="Zmluvné poisťovne" />
      <Insurance subpage />
    </>
  );
};
