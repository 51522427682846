import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/database";

const app = firebase.initializeApp({
  apiKey: "AIzaSyAeQWCUFNcIgLR2ScSpdvLTys57BDtnCZ8",
  authDomain: "medic-sro.firebaseapp.com",
  projectId: "medic-sro",
  storageBucket: "medic-sro.appspot.com",
  messagingSenderId: "676582922185",
  appId: "1:676582922185:web:924d3b56641b7b2879fa18",
  databaseURL:
    "https://medic-sro-default-rtdb.europe-west1.firebasedatabase.app/",
  measurementId: "G-CJME5DQKR8",
});

export const auth = app.auth();
export const database = app.database().ref("data");

export default app;
