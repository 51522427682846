export const Info = () => {
  const eCasenka = {
    lekarTomkova: 'https://www.ecasenka.sk/ordinacia/medicsro-mudrblazenatomkova',
    sestraTomkova: 'https://www.ecasenka.sk/ordinacia/mudrtomkova-sestricka',
    lekarTomko: 'https://www.ecasenka.sk/ordinacia/medicsro-mudrvladimirtomko',
    sestraTomko: 'https://www.ecasenka.sk/ordinacia/medicsro-mudrvladimirtomko-sestra',
  };
  return (
    <>
      <section className="schedule">
        <div className="container">
          <div className="schedule-inner">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-12">
                <div className="single-schedule middle">
                  <div className="inner">
                    <div className="icon">
                      <i className="icofont-prescription"></i>
                    </div>
                    <div className="single-content">
                      <p>
                        <b>MUDr. Blažena Tomková</b> <br /> Janka Kráľa 12, 048 01 Rožňava
                        <br />
                        <a style={{ display: 'unset' }} href="tel:+421 911 269 531">
                          +421 911 269 531
                        </a>
                        <br />
                        <b style={{ color: '#202020' }}>Lekár:</b>{' '}
                        <a style={{ display: 'unset' }} href="mailto:mudr.blazena.tomkova@gmail.com">
                          mudr.blazena.tomkova@gmail.com
                        </a>
                        <br />
                        <b style={{ color: '#202020' }}>eČasenka Lekár:</b>{' '}
                        <a style={{ display: 'unset' }} href={eCasenka.lekarTomkova}>
                          <i className="fa fa-external-link" aria-hidden="true"></i> Odkaz
                        </a>
                        <br />
                        <b style={{ color: '#202020' }}>Sestrička:</b>{' '}
                        <a style={{ display: 'unset' }} href="mailto:sestricka.tomkova@gmail.com">
                          sestricka.tomkova@gmail.com
                        </a>
                        <br />
                        <b style={{ color: '#202020' }}>eČasenka Sestra:</b>{' '}
                        <a style={{ display: 'unset' }} href={eCasenka.sestraTomkova}>
                          <i className="fa fa-external-link" aria-hidden="true"></i> Odkaz
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-12">
                <div className="single-schedule last">
                  <div className="inner">
                    <div className="icon">
                      <i className="icofont-prescription"></i>
                    </div>
                    <div className="single-content">
                      <p>
                        <b>MUDr. Vladimír Tomko</b> <br /> Okružná 30, 048 01 Rožňava
                        <br />{' '}
                        <a style={{ display: 'unset' }} href="tel: +421 948 361 468">
                          +421 948 361 468
                        </a>
                        <br />
                        <b style={{ color: '#202020' }}>Lekár:</b>{' '}
                        <a style={{ display: 'unset' }} href="mailto:mudr.vladimir.tomko@gmail.com ">
                          mudr.vladimir.tomko@gmail.com
                        </a>
                        <br />
                        <b style={{ color: '#202020' }}>eČasenka Lekár:</b>{' '}
                        <a style={{ display: 'unset' }} href={eCasenka.lekarTomko}>
                          <i className="fa fa-external-link" aria-hidden="true"></i> Odkaz
                        </a>
                        <br />
                        <b style={{ color: '#202020' }}>Sestrička: </b>
                        <a style={{ display: 'unset' }} href="mailto:sestricka.tomko@gmail.com">
                          sestricka.tomko@gmail.com
                        </a>
                        <br />
                        <b style={{ color: '#202020' }}>eČasenka Sestra:</b>{' '}
                        <a style={{ display: 'unset' }} href={eCasenka.sestraTomko}>
                          <i className="fa fa-external-link" aria-hidden="true"></i> Odkaz
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="schedule">
        <div className="container">
          <div className="schedule-inner">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-12">
                <div className="single-schedule middle">
                  <div className="inner">
                    <div className="icon">
                      <i className="icofont-ui-clock"></i>
                    </div>
                    <div className="single-content">
                      <p>
                        <b>Ordinačný čas MUDr. Blažena Tomková</b>
                      </p>
                      <ul className="time-sidual">
                        <li className="day">
                          Pondelok <span>6:30-11:00</span>
                        </li>
                        <li className="day">
                          Utorok <span>6:30-11:00</span>
                        </li>
                        <li className="day">
                          Streda <span>6:30-11:00 a 13:30-16:00</span>
                        </li>
                        <li className="day">
                          Štvrtok <span>6:30-10:00</span>
                        </li>
                        <li className="day">
                          Piatok<span>6:30-11:00</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-12">
                <div className="single-schedule last">
                  <div className="inner">
                    <div className="icon">
                      <i className="icofont-ui-clock"></i>
                    </div>
                    <div className="single-content">
                      <p>
                        <b>Ordinačný čas MUDr. Vladimír Tomko</b>
                      </p>
                      <ul className="time-sidual">
                        <li className="day">
                          Pondelok <span>6:45-12:00</span>
                        </li>
                        <li className="day">
                          Utorok <span>6:45-12:00</span>
                        </li>
                        <li className="day">
                          Streda <span>6:45-12:00 a 13:30-16:30</span>
                        </li>
                        <li className="day">
                          Štvrtok <span>6:45-11:00</span>
                        </li>
                        <li className="day">
                          Piatok<span>6:45-12:00</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
