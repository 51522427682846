export const farbocitData = [
  {
    question: 'Čo je farbocit?',
    answer:
      'Znamená to rozoznávanie farebného videnia. \
      <br/> Na sietnici oka máme špeciálne bunky - čapíky, ktorými dokážeme vnímať 3 základné farby- zelenú, červenú a modrú.',
  },
  {
    question: 'Prečo sa vyšetruje farbocit?',
    answer:
      'Farby hrajú významnú úlohu nielen pri vizuálnom vnímaní, ale aj z hľadiska bezpečnosti, napr. pri cestnej premávke, pri práci s farebnými elektrickými káblami a pod. \
    <br/> <br/> Je známe, že 8-10% mužov a 0,5% žien trpia poruchami vnímania farieb - farbocitu (to znamená, že v západnej Európe a Spojených štátoch amerických, ako aj v Japonsku žije spolu 32,6 milióna ľudí s touto poruchou).\
   <br/> <br/> Ľudia, ktorí si mýlia  alebo nedostatočne rozlíšia farby, sa nemôžu uchádzať o viac ako 150 povolaní, napr. letec, maliar, grafik, modny návrhár.\
    <br/> <br/> Porucha farbocitu je väčšinou vrodená, zriedkavo získaná, napr. pri niektorých očných ochoreniach, poškodením chemikáliami. \
    <br/> <br/> Liečba vrodenej poruchy farbocitu je prakticky nemožná, ale  do určitej miery je možné ju korigovať optickými pomôckami-okuliarovými sklami so špeciálnymi filtrami. ',
  },
  {
    question: 'Ako sa vyšetruje farbocit?',
    answer:
      'Farbosleposť má niekoľko typov podľa toho, akú farbu človek nevníma. Veľmi zriedka sa vyskytuje nevnímanie všetkých farieb, tzv. čierno-biele videnie. Najčastejším prípadom je neschopnosť človeka rozlíšiť niektorú konkrétnu farbu, zatiaľ čo ostatné farby od seba odlišuje.\
    <br/> <br/> Farbocit je možné otestovať pomocou takzvaného Ishiharoveho testu (obrázok č. 2) alebo anomaloskopom, napr. Nagelovým, určeným na veľmi podrobné poruchy farbocitu u niektorých špeciálnych povolaní ako napr. u pracovníkov železníc alebo letísk.\
    <br/> Test obsahuje niekoľko obrazcov. Úlohou je prečítať číslo alebo písmeno zložené z farebných bodiek obklopených bodkami inej farby.',
  },
];
