/* eslint-disable no-multi-str */
import { Helmet } from "react-helmet";
import { BreadCrumbs } from "../components/Global";

export const PravaPacientov = () => {
  const sections = [
    {
      heading: "Základné práva",
      description:
        "Ochrana života, zdravia a ľudskej dôstojnosti. Základné ciele, ku ktorým by sme pri poskytovaní zdravotnej starostlivosti mali vždy vzhliadať. Týka sa to ako pacienta, tak aj zdravotníckych pracovníkov.",
      items: [
        "Pri poskytovaní zdravotnej starostlivosti máte právo na ochranu života a zdravia – toto je základný princíp, ktorý by mal sprevádzať všetky úmysly a činnosti v zdravotníctve.",
        "Prístup zdravotníckych pracovníkov k pacientovi by mal byť vždy odborný, ale rovnako aj etický a humánny – vzťah zdravotnícky pracovník a pacient je však ako spojené nádoby, tak ako pacient potrebuje odbornosť a súcit, aj zdravotnícky pracovník potrebuje pozitívnu spätnú väzbu a ohľad na jeho obmedzenia.",
        "Pri poskytovaní zdravotnej starostlivosti by malo byť vždy rešpektované Vaše súkromie a Vaše osobnostné práva.",
        "Pri poskytovaní zdravotnej starostlivosti môže byť prítomný len zdravotnícky pracovník, ktorého prítomnosť je nevyhnutná pri danom výkone. Iné osoby môžu byť prítomné len na základe Vášho predchádzajúceho súhlasu - to platí aj v prípade, ak sa jedná o študentov medicíny či zdravotníckych škôl.",
        "V ambulancii sme si všetci rovní a to znamená, že pacient nesmie byť diskriminovaný z dôvodu pohlavia, náboženského vyznania alebo viery, rasy, štátnej príslušnosti, príslušnosti k národnosti alebo etnickej skupine, sexuálnej orientácie, manželského stavu a rodinného stavu, farby pleti, jazyka, politického alebo iného zmýšľania, odborovej činnosti, národného alebo sociálneho pôvodu, zdravotného postihnutia, veku, majetku, rodu alebo iného postavenia. ",
        "Máte právo poznať meno a priezvisko zdravotníckeho pracovníka ktorý Vás ošetruje.",
        "Máte právo odmietnuť transplantáciu Vašich orgánov po smrti. Toto právo môžte uplatniť podaním písomného vyhlásenia o odmietnutí transplantácie po smrti, na ktorom musí byť Váš podpis osvedčený notárom alebo na matrike. Vyhlásenie sa zasiela Národnej transplantačnej organizácii (www.nto.sk).",
        "Máte právo odmietnuť vykonanie pitvy a to zaslaním písomného oznámenia Úradu pre dohľad nad zdravotnou starostlivosťou. Oznámenie musí obsahovať Vaše osobné údaje (aby bolo možné zistiť o koho ide) a podpis (nemusí byť osvedčený notárom/matrikou). Za určitých zákonom stanovených okolností však pitva napriek jej odmietnutiu bude vykonaná:\
        pri podozrení, že mŕtva osoba bola chorá na prenosné ochorenie,\
                pri podozrení na kontamináciu mŕtveho tela rádioaktívnymi látkami,\
                pri náhlom úmrtí, ak príčina smrti nie je zjavná,\
                pri pochybnostiach lekára, ktorý vykonal prehliadku mŕtveho, o príčine smrti alebo o okolnostiach, za ktorých smrť nastala,\
                po odbere orgánov alebo tkanív od mŕtveho darcu,\
                v prípadoch násilnej smrti,\
                z dôvodov ustanovených Trestným poriadkom.",
      ],
    },
    {
      heading: "Prístup k zdravotnej starostlivosti",
      description:
        "Všetci si želáme, aby nám bola poskytnutá zdravotná starostlivosť vtedy keď ju potrebujeme. Možnosti a zdroje sú však obmedzené, preto je dobré vedieť na čo sa má pacient pripraviť.",
      items: [
        "Máte právo, aby ste získali prístup k takej zdravotnej starostlivosti, akú si vyžaduje váš zdravotný stav, vrátane preventívnej zdravotnej starostlivosti a aktivít na podporu zdravia. Skutočný obsah tohoto práva však môže podliehať objektívnym obmedzeniam (napr. nedostatok ošetrujúceho personálu, skutočnosť, že zdravotnícke zariadenie nemá dostatočné prístrojové vybavenie, keďže ho má iba špecializované pracovisko, čakacie doby na zákrok).",
        "Ak poskytovateľ zdravotnej starostlivosti nedokáže Vás zdravotný problém vyriešiť v rámci svojej odbornosti alebo s ohľadom na jeho prístrojové obmedzenia, máte právo na to, aby Vám, ak je to možné, odporučil iných poskytovateľov a informoval Vás, ako by ste mali ďalej postupovať.",
        "V prístupe k zdravotnej starostlivosti nesmiete byť neoprávnene diskriminovaný a získanie výhody iným pacientom voči Vám musí byť založené na konkrétnom a ospravedlniteľnom dôvode (napr. uprednostnenie pri zákroku v dôsledku vážnejšieho zdravotného stavu iného pacienta)",
        "Každý pacient (okrem zákonných výnimiek – pozri nižšie) má právo na výber ambulancie, ktorú bude navštevovať ako aj na jej zmenu. Môžu vás odmietnuť len vo výnimočných prípadoch:\
      neúnosné pracovné zaťaženie ambulancie,\
      osobný vzťah zdravotníckeho pracovníka, ktorý poskytuje zdravotnú starostlivosť s pacientom,\
      osobné presvedčenie zdravotníckeho pracovníka (umelé prerušenie tehotenstva, sterilizácia a asistovaná reprodukcia).",
        "Právo na výber poskytovateľa zdravotnej starostlivosti sa nevzťahuje na:\
      osobu, ktorá je vo väzbe, vo výkone trestu odňatia slobody alebo vo výkone detencie,\
      žiadateľa o azyl,\
      osobu, ktorej sa poskytuje zdravotná starostlivosť na základe rozhodnutia súdu,\
      osobu, ktorá je príslušníkom ozbrojených síl Slovenskej republiky, Policajného zboru, Slovenskej informačnej služby, Národného bezpečnostného úradu, Zboru väzenskej a justičnej stráže, Horskej záchrannej služby, Hasičského a záchranného zboru, ak takejto osobe určil poskytovateľa služobný orgán alebo služobný úrad.",
        "Ak máte trvalý pobyt alebo prechodný pobyt v zdravotnom obvode všeobecnej ambulancie pre deti/dospelých, tak takýto poskytovateľ Vás nemôže odmietnuť z dôvodu neúnosného pracovného zaťaženia.",
        "Ak vás poskytovateľ ambulantnej starostlivosti odmietne, máte právo požiadať vyšší územný celok o preverenie odmietnutia.",
        "Právo na výber poskytovateľa zdravotnej starostlivosti si netreba zamieňať s možnosťou výberu zdravotníckeho pracovníka. Zákon neukladá poskytovateľovi zdravotnej starostlivosti povinnosť vyhovieť žiadosti pacienta o to, aby ho ošetroval konkrétny zdravotnícky pracovník.",
        "Máte právo, aby Vás lekár poskytujúci ambulantnú zdravotnú starostlivosť v prípade, že si to vyžaduje Váš zdravotný stav odoslal na vyšetrenie k lekárovi – špecialistovi. Ak má byť takéto vyšetrenie uhradené zdravotnou poisťovňou, tak potrebujete výmenný lístok. Výmenný lístok nepotrebujete v prípade ak ide o:poskytnutie zdravotnej starostlivosti u psychiatra\
      poskytnutie zdravotnej starostlivosti u kožného lekára\
      poskytnutie zdravotnej starostlivosti u očného lekára pri predpise okuliarov\
      ďalšieho vyšetrenia, ktoré určí špecialista a pravidelnej lekárskej kontrole\
      poskytnutie zdravotnej starostlivosti do 24 hod. od vzniku úrazu alebo náhlej zmeny zdravotného stavu\
      ochranné liečenie (na základe rozhodnutia súdu)\
      vyšetrenia v doplnkových ordinačných hodinách",
        "Výmenný lístok nepotrebujete ani v prípade, ak si náklady zdravotnej starostlivosti uhrádzate sám z vlastných zdrojov.",
      ],
    },
    {
      heading:
        "Kto platí za zdravotnú starostlivosť ktorá mi bola/bude poskytnutá?",
      description:
        "Zdravotná starostlivosť má svoju cenu. Úhradu za pacienta vo väčšine prípadov platí zdravotná poisťovňa, ale niekedy si zdravotnú starostlivosť a s ňou súvisiace služby musí zaplatiť sám pacient. Funguje to takto:",
      items: [
        "Ak ste zdravotne poistený, tak máte nárok na to, aby za Vás zdravotná poisťovňa uhradila náklady zdravotnej starostlivosti. Uvedené neznamená, že zdravotná starostlivosť je „zadarmo“, ale znamená to, že za poskytnuté služby zaplatí zdravotná poisťovňa a nie priamo pacient. Rozsah, v ktorom má zdravotná poisťovňa zaplatiť za Vami získanú zdravotnú starostlivosť stanovuje zákon. Pokiaľ má pacient dlh na poistnom, si musí zdravotnú starostlivosť hradiť z vlastných zdrojov (okrem neodkladnej zdravotnej starostlivosti).",
        "Pokiaľ má pacient dlh na poistnom (dlží svojej zdravotnej poisťovni), tak má nárok na poisťovňou hradenú zdravotnú starostlivosť len za predpokladu, že svoj dlh voči poisťovni vyrovná (alebo s poisťovňou uzavrie aspoň dohodu o splátkach). Ak svoj dlh nemá vysporiadaný, tak má nárok iba na neodkladnú zdravotnú starostlivosť (zvyčajne stavy ohrozujúce bezprostredne jeho život alebo životne dôležité orgány, stavy vyznačujúce sa náhlou a veľkou bolesťou) a všetky ďalšie zdravotné výkony ako aj lieky a zdravotnícke pomôcky si musí uhradiť z vlastných zdrojov.",
        "Máte právo na to, aby ste vedeli, v akých cenách Váš lekár poskytuje zdravotnú starostlivos,ť ktorá nie je hradená z verejného zdravotného poistenia a aby ste boli o týchto cenách informovaný vopred. Toto právo je naplnené aj v prípade, ak má poskytovateľ zverejnený cenník na verejne dostupnom mieste alebo na svojej webovej stránke.",
        "V prípade, ak v ambulancii platíte za nejaký výkon alebo službu (napr. vydanie administratívneho potvrdenia) máte právo na to, aby Vám poskytovateľ zdravotnej starostlivosti vydal doklad o prijatej platbe.",
        "Ak nedodržujete liečebný režim, Sociálna poisťovňa Vám môže prestať platiť nemocenské dávky a zdravotná poisťovňa môže od Vás žiadať vynaložené náklady na liečbu.",
      ],
    },
    {
      heading:
        "Váš informovaný súhlas so zdravotnou starostlivosťou je základný kameň liečby",
      description:
        "Pacient je pánom svojho osudu. Zodpovedne sa však vie rozhodnúť len na základe zrozumiteľných informácií od ošetrujúceho zdravotníckeho pracovníka.",
      items: [
        "Pacient má právo zúčastňovať sa svojím rozhodovaním celého procesu zdravotnej starostlivosti, ktorá sa mu poskytuje.",
        "Pacient má právo byť informovaný o svojom zdravotnom stave, vrátane povahy ochorenia, potrebných zdravotných výkonoch a možnostiach liečby.",
        "Ak nerozumiete tomu čo lekár hovorí, tak sa ho nebojte na to upozorniť a poprosiť, aby Vám nejasnosti vysvetlil ešte raz takým spôsobom, aby ste poskytovaným informáciám porozumeli.",
        "Tak ako má pacient právo byť poučený, má rovnako právo poučenie odmietnuť a súhlas na zdravotný výkon dať aj bez poučenia. Právo ostať nepoučený pacient nemá v zákonom uvedených prípadoch, ktorými sú zdravotné výkony: biomedicínskeho výskumu, klinického skúšania liekov a zdravotníckych pomôcok, interrupcie, sterilizácie a transplantácie.",
        "Zdravotnú starostlivosť Vám zdravotnícky pracovník smie poskytnúť len v prípade, ak s tým vopred súhlasíte (okrem zákonom stanovených výnimiek – neodkladná zdravotná starostlivosť, ochranné liečenie, liečenie v dôsledku nákazlivej choroby a detencia v prípade osoby s duševnou poruchou, ktorá ohrozuje seba alebo svoje okolie).",
        "Vašim informovaným súhlasom dávate najavo, že ste boli informovaný o svojom zdravotnom stave vrátene povahy ochorenia, potrebných zdravotných výkonoch a možnostiach liečby, o následkoch a rizikách diagnostiky alebo liečby a možnostiach diagnostiky alebo liečby.",
        "Vždy máte právo odmietnuť navrhovanú liečbu alebo zdravotný výkon a rovnako máte právo rozhodnúť o ukončení liečby. O rizikách odmietnutia Vás ošetrujúci zdravotnícky pracovník musí poučiť.",
        "Hoci ste na poskytnutie zdravotnej starostlivosti dali Váš informovaný súhlas, máte právo ho kedykoľvek odvolať. Výkon tohto práva môže byť prakticky ovplyvnený druhom zdravotného výkonu, ktorý sa Vám poskytuje.",
        "Ak lekár nevie získať Váš súhlas (napr. ste odpadli) a zdravotný výkon je nevyhnutný a neodkladný, tak môže tento výkon lekár urobiť aj bez Vášho súhlasu.",
      ],
    },
    {
      heading:
        "Mlčanlivosť a ochrana osobných údajov je základom dôvery pacienta v lekára",
      description:
        "Niekedy je mlčať viac ako zlato. Pri poskytovaní zdravotnej starostlivosti to platí 100násobne. Pacientove údaje a informácie o jeho zdravotných ťažkostiach sú prísne chránené.",
      items: [
        "Všetky informácie o zdravotnom stave pacienta, diagnózach, liečbe a prognóze a aj všetky ostatné informácie osobného charakteru sú dôverné počas života pacienta aj po jeho smrti.",
        "Zdravotnícky pracovník je povinný zachovávať mlčanlivosť o tom, čo sa dozvedel o Vás a Vašom zdravotnom stave. Tejto mlčanlivosti ho môžete zbaviť Vy (pacient), alebo v odôvodnených prípadoch aj samosprávny kraj, alebo ministerstvo zdravotníctva (podľa toho, kto z nich vydal povolenie na poskytovanie zdravotnej starostlivosti).",
        "Povinnosť zdravotníckeho pracovníka zachovať mlčanlivosť o pacientovi trvá aj po smrti pacienta.",
        "Údaje zo zdravotnej dokumentácie možno poskytnúť na základe písomného vyžiadania prokurátorovi, vyšetrovateľovi, úradu práce, súdu alebo iných zákonnom vymedzeným osobám formou výpisu. Ako pacient máte právo na úplný vstup do Vašej zdravotnej dokumentácie. Pacientske práva týkajúce sa zdravotnej dokumentácie nájdete na https://www.ambulancia.online/som-pacient/co-vsetko-moze-pacient-robit-so-svojou-zdravotnou-dokumentaciou.",
        "Informácie o pacientovi a prístup k jeho zdravotnej dokumentácii sa môžu poskytnúť pre štatistické a vedecké účely v súlade s platnými právnymi predpismi.",
        "Spracúvanie osobných údajov pacienta pri poskytovaní zdravotnej starostlivosti sa deje na základe zákona a preto na takéto spracúvanie nie je potrebný súhlas pacienta. Poskytovateľ zdravotnej starostlivosti je povinný poučiť pacienta o pravidlách spracovania osobných údajov. Informácie o tom, aké údaje sa zvyčajne spracúvajú a na akých právnych základoch nájdete na https://www.ambulancia.online/som-pacient/ochrana-osobnych-udajov-pacienta-v-ambulancii.",
      ],
    },
    {
      heading:
        "Nerozumiete tomu čo sa stalo, alebo ste boli nespokojný pri poskytovaní zdravotnej starostlivosti?",
      description:
        "Lekár, sestra, či ďalší zdravotnícki pracovníci sú tiež len ľudia, ktorí z času načas robia chyby. Nikto nie je dokonalý. Skôr ako by ste podali sťažnosť na ÚDZS, či žalobu na súd dajte poskytovateľovi zdravotnej starostlivosti šancu vysvetliť to, s čím máte problém. Môžte ušetriť množstvo času a stresu sebe ale aj zdravotníckym pracovníkom.",
      items: [
        "Ak sa domnievate, že bolo porušené Vaše právo na správne poskytovanie zdravotnej starostlivosti, môžete podať sťažnosť. Je potrebné, aby ste Vašu sťažnosť podali písomne.",
        "Sťažnosť je potrebné adresovať poskytovateľovi zdravotnej starostlivosti, v zdravotníckom zariadení ktorého ste boli nespokojný.",
        "Aby mal poskytovateľ zdravotnej starostlivosti možnosť Vašu sťažnosť vyhodnotiť je potrebné, aby ste ju dostatočne odôvodnili a vysvetlili v čom spočívajú hlavné dôvody Vašej nespokojnosti a ako si predstavujete nápravu.",
        "Poskytovateľ zdravotnej starostlivosti je povinný Vás písomne informovať o spôsobe vybavenia žiadosti najneskôr do 30 dní od podania žiadosti, ak z obsahu žiadosti nevyplýva nutnosť konať bezodkladne alebo v kratšej lehote.",
        "Ak sa domnievate, že Vám zdravotná starostlivosť nebola poskytnutá správne a poskytovateľ zdravotnej starostlivosti Vaše otázky dostatočne nezodpovedal, môžte sa s podnetom obrátiť na Úrad pre dohľad nad zdravotnou starostlivosťou, ktorý správnosť postupu poskytovateľa zdravotnej starostlivosti preverí. Úrad nerozhoduje o náhrade škody a ani o žiadnych iných nárokoch pacienta. Skúma len to, či poskytovateľ postupoval správne alebo nie.",
        "Ak sa domnievate, že poskytovateľ zdravotnej starostlivosti nepostupuje správne pri vedení Vašej zdravotnej dokumentácie, žiada za zdravotnú starostlivosť, ktorá je uhrádzaná z verejného zdravotného poistenia aj platby od pacienta, alebo nedodržiava schválené ordinačné hodiny, môžete svoje podozrenie adresovať samosprávnemu kraju, v ktorom má tento poskytovateľ svoje sídlo. Vždy však pred podaním akejkoľvek sťažnosti svoje otázky adresujte danému poskytovateľovi a požiadajte ho o vysvetlenie vecí, ktoré Vám nie sú jasné. Môže sa totiž jednať len o komunikačný problém, ktorý poskytovateľ obratom vyrieši.",
        "Ak sa nazdávate, že Vám počas poskytovania zdravotnej starostlivosti boli porušené Vaše práva, máte možnosť obrátiť sa na súd. Pred podaním akejkoľvek žaloby je však vždy vhodné, aby ste svoje otázky či nespokojnosť prediskutovali s daným poskytovateľom zdravotnej starostlivosti. Mnohokrát môže Vaša nespokojnosť prameniť z nesprávnej komunikácie zdravotníckych pracovníkov a je ju možné vyriešiť diskusiou s poskytovateľom zdravotnej starostlivosti.",
        "V prípade vážneho porušenia Vašich práv máte možnosť podať trestné oznámenie. Vždy si však uvedomte, že nezodpovedné, či ľahkovážne uplatňovanie tohto práva traumatizuje zdravotníckych pracovníkov a vystavuje ich strachu pri výkone ich povolania. Uplatnenie trestnoprávnych postihov by malo prichádzať ako posledná možnosť a prostriedok nápravy vtedy, keď porušenie pacientových práv je významné a nie je ho možné napraviť inými mäkšími postupmi.",
        "Sociálne siete a médiá sú dobrý sluha, ale zlý pán. Pacient má slobodu prejavu, čo zahŕňa aj jeho rozhodnutie informovať o svojej nespokojnosti iných. S výkonom každého práva je ale spojená aj zodpovednosť. Pred zverejnením zvážte každý negatívny či obviňujúci post na sociálnych sieťach (facebook, instagram a i.), či zverejnenie referencie na rôznych portáloch. Je naozaj nevyhnutý pre ochranu Vašich práv? Alebo zbytočne vnesie do riešenia problému negatívne emócie?",
      ],
    },
  ];
  return (
    <>
      <Helmet>
        <title>Práva pacientov | M E D I C s.r.o.</title>
      </Helmet>
      <BreadCrumbs page="Práva pacientov" />
      <section className="team section single-page">
        <div className="container">
          {sections.map((section, index) => (
            <>
              <div className="my-3">
                <h2 style={{ textAlign: "center" }}> {section.heading}</h2>
                <h5 style={{ textAlign: "center" }} className="mt-2">
                  {section.description}
                </h5>
              </div>
              <ul>
                {section.items.map((item) => (
                  <li style={{ listStyle: "disc" }} className="my-3">
                    {item}
                  </li>
                ))}
              </ul>
            </>
          ))}
        </div>
      </section>
    </>
  );
};
