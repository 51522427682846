export const Care = () => {
  const services = [
    {
      heading: 'Preventívna prehliadka',
      icon: 'stethoscope',
      text: 'Na preventívnu prehliadku u všeobecného lekára máte nárok raz za dva roky. Evidovaní darcovia krvi, orgánov alebo tkanív by ju môžu absolvovať každý rok.',
    },
    {
      heading: 'Liečba bežných ochorení',
      icon: 'pills',
      text: 'Choroba je porucha zdravia, presnejšie porucha telesných, kognitívnych, sociálnych a /alebo psychických funkcií, ktoré zreteľne negatívne ovplyvňujú výkonnosť a zdravie organizmu.',
    },
    {
      heading: 'Akútne stavy',
      icon: 'operation-theater',
      text: 'Zabezpečujeme Vám a Vašim blízkym komplexnú celoročnú zdravotnú starostlivosť. Vyriešime Vaše zdravotné ťažkosti priamo u nás alebo Vám odporučíme vyšetrenie u odborného lekára mimo našej kliniky.',
    },
    {
      heading: 'Očkovanie',
      icon: 'injection-syringe',
      text: 'Očkovanie je podanie očkovacej látky (vakcíny) do organizmu, ktorý si následne vytvorí ochranné protilátky proti antigénom obsiahnutým vo vakcíne. Tie potom majú človeka alebo zviera chrániť pred infekciou vyvolanou patogénmi.',
    },
    {
      heading: 'EKG, BMI, FOB, CRP, ABI',
      icon: 'heartbeat',
      text: 'EKG môže pomôcť odhaliť srdcové ochorenia a arytmie. FOB odhalí skryté krvácanie v stolici. CRP odhalí zápal v krvi. ABI vyšetrí tuhosť Vašich tepien. BMI ukáže index Vašej telesnej hmotnosti',
    },
    {
      heading: 'Posudky',
      icon: 'patient-file',
      text: 'Sú to potvrdenia a výpisy zo zdravotnej dokumentácie o Vašom zdravotnom stave, ktoré slúžia na posúdenie napr. invalidity, odkázanosti, kompenzácii, pracovnej a indej spôsobilosti',
    },
    {
      heading: 'Glykémia',
      icon: 'blood-drop',
      text: 'Glykémia nalačno nám ukazuje hodnoty cukru v krvi po ôsmych hodinách bez príjmu potravy. U zdravých ľudí sa hladinu cukru nalačno pohybuje v rozmedzí 3,3 - 5,6 mmol/l.',
    },
    {
      heading: 'Vyšetrenie farbocitu',
      icon: 'eye',
      text: 'Okolitý svet vnímame našimi piatimi zmyslami, z ktorých je najdôležitejší zrak. Prostredníctvom zraku prijímame viac než 80% informácií z okolitého prostredia. Ak však dôjde k nejakej poruche zraku, náš život sa stáva omnoho zložitejší. ',
    },
    {
      heading: 'Elektronická objednávka receptu',
      icon: 'prescription',
      text: 'eČasenka poskytuje všetky potrebné služby ambulancie na jednom mieste. S nami si vybavíte recept, zistíte výsledky vyšetrení či sa dokážete zaradiť do poradia v čakárni z domu úplne jednoducho a bez problémov.',
    },
    {
      heading: 'Monitorovanie tlaku, oxymetria',
      icon: 'listening',
      text: 'Ambulantné 24 hodinové meranie krvného tlaku (tlakový holter) sa štandardne vykonáva v ambulanciách internistov a kardiológov, kde sú ale dlhé čakacie doby. Preto sme pre našich pacientov prístroj zakúpili aj do našej ambulancie.  Vyšetrenie je jednoduché, pohodlné a hlavne výpovedné. Pomáha objektivizovať reálny a celodenný vývoj krvného tlaku.',
    },
    {
      heading: 'Manažovanie pacientov s hypertenziou',
      icon: 'pulse',
      text: 'Zmena štýlu života vo všetkých spoločnostiach vo svete, ku ktorej došlo v súvislosti s industrializáciou a globalizáciou, viedla ku explózii vysokého krvného tlaku. Následkom tohto závažného rizika, došlo aj ku pandémii kardiovaskulárnych ochorení a s nimi súvisiacemu významnému nárastu kardiovaskulárnej a onkologickej mortality.',
    },
    {
      heading: 'Manažovanie pacientov s poruchou metabolizmu tukov',
      icon: 'blood',
      text: 'Ide o najčastejšie sa vyskytujúcu formu aterosklerózy, ktorá je bežná už aj v detskom veku. Toto nahromadenie vedie k rozvoju zápalu, k prísunu ďalších buniek obsahujúcich cholesterol, k tuhnutiu a jazveniu vnútornej steny cievy s vytvorením sklerotického plátu.',
    },
  ];
  return (
    <>
      <section className="services section" style={{ paddingTop: '0px' }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title">
                <h2>Ponúkame rôzne služby na zlepšenie vášho zdravia</h2>
                <img src="img/section-img.png" alt="#" />
              </div>
            </div>
          </div>
          <div className="row">
            {services.map((service, index) => (
              <div className="col-lg-4 col-md-6 col-12">
                <div className="single-service">
                  <i style={{ animation: 'none' }} className={`icofont icofont-${service.icon}`}></i>
                  <h5 className="mb-2" style={{ textTransform: 'none' }}>
                    {service.heading}
                  </h5>
                  <p>{service.text}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};
