import { Helmet } from "react-helmet";
import { BreadCrumbs } from "../components/Global";

export const Cookies = () => {
  return (
    <>
      <Helmet>
        <title>Cookies | M E D I C s.r.o.</title>
      </Helmet>
      <BreadCrumbs page="Cookies" />
      <div className="container my-4">
        <h1 style={{ textAlign: "center" }}>Poučenie o cookies</h1>
        <p className="my-3">
          Súbory cookies sú malé textové súbory, ktoré do počítača užívateľa
          stránky alebo iných zariadení s prístupom na internet umiestňujú naše
          internetové stránky. Tieto súbory nám umožňujú odlíšiť užívateľa od
          ostatných používateľov našej internetovej stránky a tiež nám umožňujú
          našu internetovú stránku skvalitňovať a zlepšiť spôsoby, akými si ju
          môže užívateľ prezerať.
          <br /> <br />
          Cookies sa posielajú späť na serveri internetovej stránky, ktorá ich
          uložila, keď sa užívateľ vráti na túto internetovú stránku. Cookies a
          údaje získané prostredníctvom internetových stránok Poskytovateľa
          služby využívajúcich technológiu cookies môžu byť použité najmä na
          umožnenie pohybu po internetovej stránke a využívanie jej funkcií,
          zhromažďovanie informácií o tom, ako užívatelia internetovej stránky
          používajú stránku (ako napr. ktoré internetové stránky sú navštevované
          najčastejšie alebo či užívatelia internetovej stránky prijímajú správy
          o chybách atď.), uchovávanie údajov o výberoch uskutočnených
          užívateľmi internetovej stránky (ako napr. voľba užívateľského mena,
          výber jazyka alebo regiónu atď.), reklamné a marketingové účely atď..
          Prostredníctvom súborov cookies a podobných technológií môžeme
          získavať aj informácie o tom, ako stránku užívateľ používa. Vďaka nim
          dokážeme zistiť, čomu dáva užívateľ prednosť a prispôsobiť sa tomu.
          Táto technológia zhromažďuje (tzv. clickstream) údaje, ktorých
          súčasťou nie sú žiadne informácie, na základe ktorých by bolo možné
          zneužiť napr. prihlasovacie údaje užívateľa stránky.
          <br /> <br />
          Údaje zhromažďované prostredníctvom cookies odzrkadľujú aktivitu
          užívateľa na stránke a pomáhajú nám prispôsobiť stránku a predvídať,
          čo by mohlo našich používateľov zaujímať, a to tak, že monitorujú
          návštevnosť obľúbených oblastí a modifikujú služby a informácie. To by
          malo viesť k zvyšovaniu spokojnosti užívateľov našej stránky.
          Prostredníctvom cookies sa ukladá jedinečný identifikátor relácie,
          ktorý nám umožňuje opätovne načítať profil a predvoľby užívateľa pri
          jeho ďalšej návšteve internetovej stránky.
          <br /> <br />
          Používame nasledovné typy súborov cookies:
        </p>
        <table>
          {" "}
          <tbody>
            <tr>
              <td style={{ border: "1px solid black", padding: "5px" }}>
                <strong>Názov cookie</strong>
              </td>
              <td style={{ border: "1px solid black", padding: "5px" }}>
                <strong>Funkcia cookie</strong>
              </td>
              <td style={{ border: "1px solid black", padding: "5px" }}>
                <strong>Dĺžka trvania</strong>
              </td>
            </tr>
            <tr>
              <td style={{ border: "1px solid black", padding: "5px" }}>
                <p>_ga</p>

                <p>_gat</p>
              </td>
              <td style={{ border: "1px solid black", padding: "5px" }}>
                Google Analytics tracking Anonymné cookie
                umožňujúci&nbsp;analýzu návštevnosti pre nástoj Google Analytics
                – zber štatistík o&nbsp;dĺžke pobytu na stránke, aké stránky si
                návštevník pozrel, odkiaľ prišiel, počet návštevníkov na
                stránke.
              </td>
              <td style={{ border: "1px solid black", padding: "5px" }}>
                24 hodín
              </td>
            </tr>
            <tr>
              <td style={{ border: "1px solid black", padding: "5px" }}>
                _gid
              </td>
              <td style={{ border: "1px solid black", padding: "5px" }}>
                <p>Google Analytics tracking</p>

                <p>
                  Anonymné cookie umožńujúci&nbsp;analýzu návštevnosti pre
                  nástoj Google Analytics – zber štatistík o&nbsp;dĺžke pobytu
                  na stránke, aké stránky si návštevník pozrel, odkiaľ prišiel,
                  počet návštevníkov na stránke.
                </p>
              </td>
              <td style={{ border: "1px solid black", padding: "5px" }}>
                2 roky
              </td>
            </tr>

            <tr>
              <td style={{ border: "1px solid black", padding: "5px" }}>
                gcp_cookie_accept
              </td>
              <td style={{ border: "1px solid black", padding: "5px" }}>
                Uloží informáciu o súhlase s používaim cookies
              </td>
              <td style={{ border: "1px solid black", padding: "5px" }}>
                1 rok
              </td>
            </tr>
          </tbody>
        </table>
        <p>
          Súbory cookies používame na tejto internetovej stránke na nasledovné
          účely:
          <br />
          <br />
          <ul>
            <li style={{ listStyle: "disc" }}>
              {" "}
              navigovanie požívateľov na príslušné časti stránky;
            </li>
            <li style={{ listStyle: "disc" }}>
              zabezpečenie, aby stránka vyzerala v rôznych prehliadačoch a
              rôznych zariadeniach konzistentne;
            </li>
            <li style={{ listStyle: "disc" }}>
              {" "}
              zaistenie funkčnosti zložitých oblastí stránky;
            </li>
            <li style={{ listStyle: "disc" }}>
              sledovanie súhrnných štatistík o návštevách stránky s cieľom
              zlepšiť jej výkonnosť;
            </li>
          </ul>
          <br />
          <br />
          Používame len súbory cookies prvej strany a neumožňujeme iným
          internetovým stránkam ani tretím stranám, aby naše súbory cookies
          používali na svoje vlastné účely alebo aby v prípade návštevy
          užívateľa na našej stránke umiestňovali do užívateľovho počítača svoje
          vlastné súbory cookies.
          <br />
          <br />
          Väčšina internetových prehliadačov cookies automaticky akceptuje, ak
          prehliadač nie je nastavený inak. Zároveň ale väčšina internetových
          prehliadačov umožňuje spravovať súbory cookies prostredníctvom
          nastavení prehliadača. Svoj prehliadač si užívateľ môže nastaviť tak,
          aby ho upozornil na príjem súboru cookie, vďaka čomu sa bude môcť
          rozhodnúť, či daný súbor chce prijať, alebo nie. Ak však nejaký
          konkrétny súbor cookie neprijme, niektoré prvky stránky nemusia
          fungovať správne alebo stránky nemusia byť úplné. Použitie cookies
          môže užívateľ obmedziť alebo úplne zablokovať v nastaveniach
          prehliadača.
          <br />
          <br />
          Informácie o nastavení konkrétneho internetového prehliadača nájdete
          aj na uvedených adresách:
          <br />
          Internet Explorer:{" "}
          <b>
            <a href="https://support.microsoft.com">support.microsoft.com</a>
          </b>
          <br />
          Microsoft Edge:{" "}
          <b>
            <a href="https://support.microsoft.com">support.microsoft.com</a>
          </b>
          <br />
          Google Chrome:{" "}
          <b>
            <a href="https:// support.google.com"> support.google.com</a>
          </b>
          <br />
          Mozilla Firefox:{" "}
          <b>
            <a href="https://support.mozilla.org">support.mozilla.org</a>
          </b>
          <br />
          Firefox Quantum:{" "}
          <b>
            <a href="https://support.mozilla.org">support.mozilla.org</a>
          </b>
          <br />
          Opera:{" "}
          <b>
            <a href="https://help.opera.com"> help.opera.com</a>
          </b>
          <br />
          Safari:{" "}
          <b>
            <a href="https://support.apple.com">support.apple.com</a>
          </b>
          <br />
          <br />
          Zároveň si dovoľujeme informovať užívateľov našich stránok o tom, že
          nastavenie ich internetového prehliadača alebo iného počítačového
          programu, ktoré nebude blokovať používanie cookies (t.j. nastavenie,
          ktoré nebude blokovať resp. zakazovať použitie cookies alebo
          nastavenie, ktoré bude použitie cookies priamo povoľovať), sa považuje
          za súhlas s používaním cookies a údajov získaných prostredníctvom nich
          na všetky účely uvedené vyššie.
          <br />
          <br />
          IP adresa. Adresa internetového protokolu (IP) predstavuje sériu
          čísel, ktoré sú automaticky priradené užívateľovmu počítaču kedykoľvek
          sa pripojuje k poskytovateľovi jeho internetových služieb. Webové
          servery automaticky identifikujú počítač užívateľa prostredníctvom
          pridelenej IP adresy počas doby, kedy je užívateľ on-line. Dovoľujeme
          si informovať užívateľov našich stránok o tom, že môžeme zhromažďovať
          informácie o počítači užívateľa, vrátane IP adresy, o type operačného
          systému a internetového prehliadača za účelom administrácie systému a
          auditu používania internetovej stránky. Nespojujeme IP adresu
          užívateľa internetovej stránky so žiadnymi údajmi, prostredníctvom
          ktorých by mohol byť osobne identifikovaný, t.j. každé pripojenie
          užívateľa je zaznamenané, ale užívateľ zostane pre nás anonymný. IP
          adresa ale môže byť použitá k identifikácii používateľov internetovej
          stránky, ak to považujeme za nutné z dôvodu vynútenia dodržiavania
          Všeobecných podmienok používania portálu e-medius.sk alebo pre ochranu
          našich služieb, internetovej stránky alebo iných užívateľov.
          <br />
          <br />
          Ak by ste mali ku cookies a k tomuto dokumentu akékoľvek otázky,
          pýtajte sa nás na{" "}
          <b>
            <a href="mailto:info@becode.sk">info@becode.sk</a>
          </b>
          .
        </p>
      </div>
    </>
  );
};
