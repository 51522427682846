import { Helmet } from 'react-helmet';
import { BreadCrumbs } from '../components/Global';

export const Obvod = () => {
  const streets = [
    'Dovčíkova',
    'Juraja Marikovszkého',
    'Jána Brocku',
    'Kolomana Tichyho',
    'Kozmonautov',
    'Majerská',
    'Martina Kukučína',
    'Námestie Baníkov',
    'Ružová',
    'Slnečná',
    'Útulná',
    'Vítéz Kert',
    'Sama Tomášika',
    'Zeleného Stromu',
  ];
  return (
    <>
      <Helmet>
        <title>Obvod | M E D I C s.r.o.</title>
      </Helmet>
      <BreadCrumbs page="Obvod" />
      <section className="about-area section">
        <div className="container-fluid p-0">
          <div className="row m-0">
            <div className="col-lg-6 col-md-12 p-0">
              <div className="about-image"></div>
            </div>
            <div className="col-lg-6 col-md-12 p-0">
              <div className="about-content">
                <h2>Náš obvod sa skladá z následujúcich ulíc:</h2>

                <ul>
                  {streets.map((item) => (
                    <li>
                      <i className="icofont-tick-mark" /> {item}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
