export const AktualityTab = ({ item }: any) => {
  const { phone = '', person = '', date = '', address = '' } = item.state;
  const { pondelok, utorok, streda, stvrtok, piatok } = {
    pondelok: item.state?.days?.pondelok || '',
    utorok: item.state?.days?.utorok || '',
    streda: item.state?.days?.streda || '',
    stvrtok: item.state?.days?.stvrtok || '',
    piatok: item.state?.days?.piatok || '',
  };

  const allDays = !!pondelok && !!utorok && !!streda && !!stvrtok && !!piatok;

  const isEnabled = person && date;
  const isEnabledOnlyText = !!date;

  switch (item.path) {
    case 'dovolenka':
      return (
        <div className="col-12">
          <div className="single-team">
            <div className="t-bottom">
              <p>Dovolenka</p>
              {isEnabled ? (
                <>
                  <h2>
                    Termín: <a href="#">{date}</a>
                  </h2>

                  <h2>
                    Zastupuje:<a href="#">{person}</a>
                  </h2>

                  {address && (
                    <h2>
                      Adresa:<a href="#">{address}</a>
                    </h2>
                  )}
                  {phone && (
                    <h2>
                      Telefón:<a href="#">{phone}</a>
                    </h2>
                  )}

                  {allDays && (
                    <>
                      <br />
                      <h2>
                        Ordinačné hodiny:
                        <br />
                        <small>Pondelok: {pondelok}</small>
                        <br />
                        <small>Utorok: {utorok}</small>
                        <br />
                        <small>Streda: {streda}</small>
                        <br />
                        <small>Štvrtok: {stvrtok}</small>
                        <br />
                        <small>Piatok: {piatok}</small>
                      </h2>
                    </>
                  )}
                </>
              ) : (
                <h2>Momentálne nie je žiadna dovolenka</h2>
              )}
            </div>
          </div>
        </div>
      );
    case 'zastupovanie':
      return (
        <div className="col-12">
          <div className="single-team">
            <div className="t-bottom">
              <p>Zastupovanie</p>
              {isEnabled ? (
                <>
                  <h2>
                    Termín: <a href="#">{date}</a>
                  </h2>
                  <h2>
                    Zastupuje:<a href="#">{person}</a>
                  </h2>
                </>
              ) : (
                <h2>Momentálne nie je žiadne zastupovanie</h2>
              )}
            </div>
          </div>
        </div>
      );
    case 'posudkovakomisia':
      return (
        <div className="col-12">
          <div className="single-team">
            <div className="t-bottom">
              <p>Posudková komisia</p>
              {isEnabledOnlyText ? (
                <h2>
                  Termín: <a href="#">{date}</a>
                </h2>
              ) : (
                <h2>Momentálne nie je žiadna posudková komisia</h2>
              )}
            </div>
          </div>
        </div>
      );
    case 'ineAktuality':
      return (
        <div className="col-12">
          <div className="single-team">
            <div className="t-bottom">
              <p>Iné</p>
              {isEnabledOnlyText ? (
                <h2>
                  <a href="#">{date}</a>
                </h2>
              ) : (
                <h2>Momentálne nie sú žiadne iné novinky</h2>
              )}
            </div>
          </div>
        </div>
      );
    default:
      return <></>;
  }
};
