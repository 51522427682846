export const Footer = () => {
  return (
    <footer id="footer" className="footer ">
      <div className="footer-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6 col-12">
              <div className="single-footer">
                <h2>Firemné údaje</h2>
                <p>
                  <b>IČO</b> : 45 372 713{' '}
                </p>
                <p>
                  <b>DIČ</b> : 2022976747{' '}
                </p>
                <p>
                  <b>ČÚ</b> : SK58 0900 0000 0005 1285 6777{' '}
                </p>
                <p>
                  <b>Pov. VÚC</b> : 6657/2018/OSVaZ-32091{' '}
                </p>
                <p>
                  <b>Pov. súdu</b> : 25077/V{' '}
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-12">
              <div className="single-footer">
                <h2>Kontakt</h2>
                <p>
                  <b>MUDr. Blažena Tomková</b> <br /> Janka Kráľa 12 048 01 Rožňava
                  <br />
                  <a href="tel:+421 911 269 531">+421 911 269 531</a>
                  <br />
                  mudr.blazena.tomkova@gmail.com
                  <br />
                  sestricka.tomkova@gmail.com
                </p>
                <br />
                <p>
                  <b>MUDr. Vladimír Tomko</b> <br /> Okružná 30 04801 Rožňava
                  <br />
                  <a href="tel:+421 948 361 468">+421 948 361 468</a>
                  <br />
                  mudr.vladimir.tomko@gmail.com
                  <br />
                  sestricka.tomko@gmail.com
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-12">
              <div className="single-footer f-link">
                <h2>Dôležité odkazy</h2>
                <div className="row">
                  <div className="col-12">
                    <ul>
                      <li>
                        <a href="/ordinacny-cas">
                          <i className="fa fa-caret-right" aria-hidden="true"></i>
                          Ordinačné hodiny
                        </a>
                      </li>
                      <li>
                        <a href="/poistovne">
                          <i className="fa fa-caret-right" aria-hidden="true"></i>
                          Zmluvné poisťovne
                        </a>
                      </li>
                      <li>
                        <li>
                          <a href="/ochrana-osobnych-udajov">
                            <i className="fa fa-caret-right" aria-hidden="true"></i>
                            Ochrana osobných údajov
                          </a>
                        </li>

                        <a href="/dotaznik-spokojnosti">
                          <i className="fa fa-caret-right" aria-hidden="true"></i>
                          Dotazník spokojnosti
                        </a>
                      </li>
                      <li>
                        <a href="/prava-pacientov">
                          <i className="fa fa-caret-right" aria-hidden="true"></i>
                          Práva pacientov
                        </a>
                      </li>
                      <li>
                        <a href="/dolezite-kontakty">
                          <i className="fa fa-caret-right" aria-hidden="true"></i>
                          Dôležité kontakty
                        </a>
                      </li>
                      <li>
                        <a href="/cookies">
                          <i className="fa fa-caret-right" aria-hidden="true"></i>
                          Cookies
                        </a>
                      </li>
                      <li>
                        <a href="/admin">
                          <i className="fa fa-caret-right" aria-hidden="true"></i>
                          Administrácia
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 col-12">
              <div className="single-footer f-link">
                <h2>Zdravotná starostlivosť</h2>
                <div className="row">
                  <div className="col-12">
                    <ul>
                      <li>
                        <a href="/covid">
                          <i className="fa fa-caret-right" aria-hidden="true"></i>
                          COVID-19
                        </a>
                      </li>
                      <li>
                        <a href="/tetanus">
                          <i className="fa fa-caret-right" aria-hidden="true"></i>
                          Očkovanie proti tetanu
                        </a>
                      </li>
                      <li>
                        <a href="/zdravotna-starostlivost">
                          <i className="fa fa-caret-right" aria-hidden="true"></i>
                          ďalšie...
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <h2 className="mt-4 mb-3">Formuláre</h2>
                <div className="row">
                  <div className="col-12">
                    <ul>
                      <li>
                        <a href="/formulare">
                          <i className="fa fa-caret-right" aria-hidden="true"></i>
                          Formuláre
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="copyright">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-12">
              <div className="copyright-content">
                <p>
                  © Copyright {new Date().getFullYear()} | Webstránky pre lekárov |{' '}
                  <a href="https://becode.sk" target="_blank" rel="noreferrer">
                    BeCode s.r.o.
                  </a>{' '}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
