import { Helmet } from "react-helmet";
import { BreadCrumbs } from "../components/Global";

export const DotaznikSpokojnosti = () => {
  return (
    <>
      <Helmet>
        <title>Dotazník spokojnosti | M E D I C s.r.o.</title>
      </Helmet>
      <BreadCrumbs page="Dotazník spokojnosti" />
      <div className="container py-5">
        <div className="row justify-content-center align-items-center">
          <iframe
            title="dotaznik"
            id="iframeX6D3A1V8A8F1F9Y6J"
            src="https://www.survio.com/survey/i/Q9Z9T2B7V2X5S9V7O"
            height="600"
            width="800"
          />
        </div>
      </div>
    </>
  );
};
