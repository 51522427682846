import { Helmet } from "react-helmet";
import { BreadCrumbs } from "../components/Global";

export const Lieky = () => {
  return (
    <>
      <Helmet>
        <title>Výdaj liekov | M E D I C s.r.o.</title>
      </Helmet>
      <BreadCrumbs page="Výdaj liekov" />
      <h2 style={{ textAlign: "center" }}>
        Na tejto stránke momentálne pracujeme
      </h2>
    </>
  );
};
