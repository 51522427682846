import { Helmet } from "react-helmet";
import { BreadCrumbs } from "../components/Global";

export const OsobneUdaje = () => {
  return (
    <>
      <Helmet>
        <title>Ochrana osobných údajov | M E D I C s.r.o.</title>
      </Helmet>
      <BreadCrumbs page="Ochrana osobných údajov" />
      <section className="team section single-page">
        <div className="container">
          <div className="mb-3">
            <h2 style={{ textAlign: "center" }}>
              Spracúvanie a ochrana osobných údajov pacienta v ambulancii
            </h2>
            <h5 style={{ textAlign: "center" }} className="mt-2">
              Ako získava ambulancia moje osobné údaje?
            </h5>
            <h5 style={{ textAlign: "center" }}>
              Prečo a na akých právnych základoch ich spracováva?
            </h5>
          </div>
          <ul>
            <li style={{ listStyle: "disc" }} className="my-3">
              Ako o mne ambulancia získava osobné údaje? Vaše osobné údaje
              ambulancia získava priamo od Vás, resp. Vašich zákonných
              zástupcov. Ak Vám má byť poskytnutá zdravotná starostlivosť, je
              zákonnou povinnosťou ambulancie spracúvať Vaše osobné údaje,
              prípadne osobné údaje zástupcov. Súhlas dotknutej osoby na
              spracúvanie, poskytovanie a sprístupňovanie údajov zo zdravotnej
              dokumentácie sa za podmienok ustanovených zákonom č. 576/2004 Z.z
              nevyžaduje. Neposkytnutie osobných údajov v potrebnom rozsahu
              danom týmto zákonom môže mať za následok neposkytnutie zdravotnej
              starostlivosti okrem akútnych stavov.
            </li>
            <li style={{ listStyle: "disc" }} className="mb-3">
              Prečo ambulancia spracováva moje osobné údaje a na základe čoho
              (právny základ)? Dôvody, prečo ambulancia spracováva Vaše osobné
              údaje a na akom právnom základe tak koná, sú uvedené v
              nasledujúcej tabuľke:
            </li>
          </ul>
          <table>
            {" "}
            <tbody>
              <tr>
                <td
                  style={{ border: "1px solid black", padding: "5px" }}
                  colSpan={2}
                >
                  <p>
                    <strong>
                      Prečo ambulancia spracováva vaše osobné údaje?
                    </strong>
                  </p>
                </td>
                <td
                  style={{ border: "1px solid black", padding: "5px" }}
                  rowSpan={2}
                >
                  <p>
                    <strong>
                      Na akom právnom základe sa spracúvajú Vaše osobné údaje?
                    </strong>
                  </p>
                </td>
              </tr>
              <tr>
                <td style={{ border: "1px solid black", padding: "5px" }}>
                  <p>
                    <strong>Názov informačného systému</strong>
                  </p>
                </td>
                <td style={{ border: "1px solid black", padding: "5px" }}>
                  <p>
                    <strong>Účel spracúvania Vašich osobných údajov</strong>
                  </p>
                </td>
              </tr>
              <tr>
                <td style={{ border: "1px solid black", padding: "5px" }}>
                  <p>Poskytovanie zdravotnej starostlivosti</p>

                  <p>&nbsp;</p>
                </td>
                <td style={{ border: "1px solid black", padding: "5px" }}>
                  <p>Preto, aby sa&nbsp;mohlo:</p>

                  <ul>
                    <li>
                      <p>
                        Poskytovať zdravotnú starostlivosť pacientom a plniť
                        ďalšie&nbsp;zákonné povinnosti pri poskytovaní
                        zdravotnej starostlivosti, najmä viesť zdravotnú
                        dokumentáciu v papierovej forme alebo elektronickej
                        zdravotnej knižke v národnom zdravotníckom informačnom
                        systéme, povinné zákonné hlásenia.
                      </p>
                    </li>
                    <li>
                      <p>
                        Plniť&nbsp;povinnosti voči zdravotným poisťovniam. Plniť
                        zákonné povinnosti pri predpisovaní humánnych liekov,
                        zdravotníckych pomôcok a dietetických potravín.
                      </p>
                    </li>
                    <li>
                      <p>
                        Plniť zákonné povinnosti bezodkladne oznamovať Štátnemu
                        ústavu pre kontrolu liečiv podozrenia na nežiadúce
                        účinky humánneho lieku,&nbsp;o ktorých sa dozvedeli po
                        registrácii humánneho lieku.
                      </p>
                    </li>
                  </ul>
                </td>
                <td style={{ border: "1px solid black", padding: "5px" }}>
                  <p>Plnenie zmluvných a zákonných povinností.</p>

                  <p>&nbsp;</p>
                </td>
              </tr>
              <tr>
                <td style={{ border: "1px solid black", padding: "5px" }}>
                  <p>Objednávanie pacientov</p>

                  <p>&nbsp;</p>
                </td>
                <td style={{ border: "1px solid black", padding: "5px" }}>
                  <p>Preto, aby sa&nbsp;mohlo:</p>

                  <p>
                    Viesť knihu objednaných pacientov a evidenciu termínov, na
                    ktoré boli objednaní pacienti.
                  </p>
                </td>
                <td style={{ border: "1px solid black", padding: "5px" }}>
                  <p>Plnenie zmluvných povinností.</p>

                  <p>&nbsp;</p>
                </td>
              </tr>
              <tr>
                <td style={{ border: "1px solid black", padding: "5px" }}>
                  <p>Sťažnosti pacientov</p>

                  <p>&nbsp;</p>
                </td>
                <td style={{ border: "1px solid black", padding: "5px" }}>
                  <p>Preto, aby sa&nbsp;mohlo:</p>

                  <p>
                    Vybavovať sťažnosti pacientov v súvislosti s poskytnutím
                    zdravotnej starostlivosti.
                  </p>
                </td>
                <td style={{ border: "1px solid black", padding: "5px" }}>
                  <p>Plnenie zákonných povinností.</p>

                  <p>&nbsp;</p>
                </td>
              </tr>
              <tr>
                <td style={{ border: "1px solid black", padding: "5px" }}>
                  <p>Účtovné doklady</p>

                  <p>&nbsp;</p>
                </td>
                <td style={{ border: "1px solid black", padding: "5px" }}>
                  <p>Preto, aby&nbsp;sa mohlo:</p>

                  <p>
                    Vyhotoviť účtovný doklad, prijať platbu a evidovať účtovný
                    doklad na účel uplatnenia nároku na úhradu za poskytnutú
                    zdravotnú starostlivosť a za služby súvisiace so zdravotnou
                    starostlivosťou v súlade s cenníkom.
                  </p>
                </td>
                <td style={{ border: "1px solid black", padding: "5px" }}>
                  <p>Plnenie zákonných povinností.</p>
                </td>
              </tr>
              <tr>
                <td style={{ border: "1px solid black", padding: "5px" }}>
                  <p>Evidencia prijatej a odoslanej pošty</p>
                </td>
                <td style={{ border: "1px solid black", padding: "5px" }}>
                  <p>Preto, aby sa&nbsp;mohlo:</p>

                  <p>Viesť evidenciu prijatej a odoslanej pošty.</p>
                </td>
                <td style={{ border: "1px solid black", padding: "5px" }}>
                  <p>Plnenie zákonných povinností.</p>
                </td>
              </tr>
              <tr>
                <td style={{ border: "1px solid black", padding: "5px" }}>
                  <p>Kamerové systémy</p>

                  <p>&nbsp;</p>
                </td>
                <td style={{ border: "1px solid black", padding: "5px" }}>
                  <p>Preto, aby sa&nbsp;mohoi:</p>

                  <p>
                    Chrániť aktíva, zabezpečovať prevenciu a ochranu práv&nbsp;a
                    právom chránených záujmov zdravotníkov&nbsp;a
                    &nbsp;pacientov.
                  </p>

                  <p>&nbsp;</p>
                </td>
                <td style={{ border: "1px solid black", padding: "5px" }}>
                  <p>Oprávnený záujem zdravotníkov.</p>

                  <p>&nbsp;</p>
                </td>
              </tr>
              <tr>
                <td style={{ border: "1px solid black", padding: "5px" }}>
                  <p>Súdne spory</p>

                  <p>&nbsp;</p>
                </td>
                <td style={{ border: "1px solid black", padding: "5px" }}>
                  <p>Preto, aby sa&nbsp;mohoi:</p>

                  <p>
                    Preukazovať, uplatňovať, obhajovať alebo brániť právne
                    nároky v prípade súdnach sporov.
                  </p>

                  <p>&nbsp;</p>
                </td>
                <td style={{ border: "1px solid black", padding: "5px" }}>
                  <p>Oprávnený záujem zdravotníkov.</p>

                  <p>&nbsp;</p>
                </td>
              </tr>
              <tr>
                <td style={{ border: "1px solid black", padding: "5px" }}>
                  <p>Uplatňovanie práv dotknutej osoby</p>
                </td>
                <td style={{ border: "1px solid black", padding: "5px" }}>
                  <p>Preto, aby sa&nbsp;mohlo:</p>

                  <p>
                    Vybavovať žiadosti v súvislosti s uplatňovaním práv
                    dotknutých osôb.
                  </p>
                </td>
                <td style={{ border: "1px solid black", padding: "5px" }}>
                  <p>Plnenie zákonných povinností.</p>
                </td>
              </tr>
            </tbody>
          </table>
          <div className="my-3">
            <h2 style={{ textAlign: "center" }}>
              Čo sa deje s osobnými údajmi, poskytnutými ambulancii?
            </h2>
            <h5 style={{ textAlign: "center" }} className="mt-2">
              Ako dlho ambulancia uchováva moje osobné údaje?
            </h5>
            <h5 style={{ textAlign: "center" }}>
              Zverejňuje alebo poskytuje ich niekomu?
            </h5>
          </div>
          <ul>
            <li style={{ listStyle: "disc" }} className="my-3">
              Poskytuje ambulancia niekomu moje osobné údaje? Osobné údaje a
              údaje týkajúce sa zdravia pacientov sa majú poskytovať iným osobám
              výhradne len ak táto povinnosť vyplýva zo zákona, a to najmä
              zdravotným poisťovniam, na vyžiadanie Úradu pre dohľad nad
              zdravotnou starostlivosťou, zriaďovateľovi na účely prešetrenia
              podnetov, Národnému centru zdravotníckych informácií v súvislosti
              s vedením elektronickej zdravotnej knižky, poskytovateľom
              lekárenskej starostlivosti, osobám vymenovaným v § 24 a § 25
              zákona č. 576/2004 Z.z., s výnimkou spoločností zabezpečujúcich
              podporu prevádzky informačných systémov zdravotníckeho zariadenia.
              Ak máte záujem o bližšiu špecifikáciu príjemcov, môžete
              kontaktovať Vášho lekára.
            </li>
            <li style={{ listStyle: "disc" }} className="mb-3">
              Ako dlho ambulancia uchováva moje osobné údaje? Vašu zdravotnú
              dokumentáciu (vrátane predpisovania humánnych liekov,
              zdravotníckych pomôcok a dietetických potravín a dokumentácie
              týkajúcej sa zákonných zástupcov) ambulancia uchováva 20 rokov po
              smrti pacienta, ak hovoríme o všeobecnom lekárovi alebo 20 rokov
              od posledného poskytnutia zdravotnej starostlivosti, v prípade
              špecialistov. Po uplynutí zákonnej lehoty sú údaje určené na
              likvidáciu. Ostatné lehoty na uchovávanie osobných údajov máme
              uvedené v našich záznamoch o spracúvaní osobných údajov. Pre
              bližšie oboznámenie sa s jednotlivými dobami, prosím, kontaktujte
              lekára a ten Vám poskytne všetky doplňujúce informácie.
            </li>
            <li style={{ listStyle: "disc" }} className="mb-3">
              Zverejňuje ambulancia moje osobné údaje? Alebo ich nejako prenáša
              do tretích krajín? Vaše osobné údaje sa nezverejňujú a neprenášajú
              do tretích krajín.
            </li>
            <li style={{ listStyle: "disc" }} className="mb-3">
              A ako je to s profilovaním a automatizovaným rozhodovaním? Vaše
              osobné údaje nepodliehajú automatizovanému rozhodovaniu, ani
              profilovaniu.
            </li>
          </ul>
          <div className="my-3">
            <h2 style={{ textAlign: "center" }}>
              Práva pacienta pri ochrane osobných údajov
            </h2>
            <h5 style={{ textAlign: "center" }} className="mt-2">
              Práva pacienta pri ochrane osobných údajov Aké mám práva v
              súvislosti so spracúvaním osobných údajov a ako ich môžem
              uplatniť?
            </h5>
          </div>

          <ul>
            <li style={{ listStyle: "disc" }} className="my-3">
              Máte právo požadovať potvrdenie o tom, či sa spracúvajú vaše
              osobné údaje, ak je to tak, máte právo získať prístup k týmto
              údajom (kópiu spracúvaných osobných údajov). Potvrdenie vám
              poskytne ambulancia v písomnej podobe, a to buď poštou alebo
              elektronicky.
            </li>
            <li style={{ listStyle: "disc" }} className="mb-3">
              Máte právo získať osobné údaje, ktoré sa vás týkajú a ktoré ste
              poskytli ambulancii, a tiež právo preniesť tieto údaje ďalšiemu
              poskytovateľovi zdravotnej starostlivosti. Taktiež máte právo na
              poskytnutie údajov zo zdravotnej dokumentácie formou výpisu a na
              sprístupnenie údajov formou nahliadania do zdravotnej
              dokumentácie.
            </li>
            <li style={{ listStyle: "disc" }} className="mb-3">
              Máte právo, aby ambulancia bez zbytočného odkladu opravila
              nesprávne osobné údaje, ktoré sa vás týkajú, a aby doplnila
              neúplné osobné údaje. Je dôležité, aby ambulancia mala o vás
              správne informácie a vy, aby ste ju upozornili, ak je niektorý z
              vašich osobných údajov nesprávny, napr. ak ste si zmenili meno, ak
              ste sa presťahovali alebo zmenili zdravotnú poisťovňu.
            </li>
            <li style={{ listStyle: "disc" }} className="mb-3">
              Máte právo na vymazanie (zabudnutie) osobných údajov, len ak tieto
              údaje už nie sú potrebné na účely, na ktoré sa získavali a
              spracovali alebo ak nepodliehajú iným zákonným požiadavkám (napr.
              požiadavke viesť zdravotnú dokumentáciu). Preto nemôžete lekára
              žiadať o nezapísanie údajov do zdravotnej dokumentácie, o jej
              zničenie počas zákonnej lehoty na jej uchovanie alebo o vymazanie
              údajov z dokumentácie, pokiaľ to zákon nedovoľuje.
            </li>
            <li style={{ listStyle: "disc" }} className="mb-3">
              Ak boli osobné údaje u príjemcu osobných údajov opravené alebo
              vymazané, máte právo byť o tom informovaný.
            </li>
            <li style={{ listStyle: "disc" }} className="mb-3">
              Právo na to, aby ambulancia obmedzila spracúvanie osobných údajov,
              ak: napadnete správnosť osobných údajov, a to počas obdobia
              overovania ich správnosti; spracúvanie je protizákonné a namietate
              proti vymazaniu osobných údajov a žiadate namiesto toho obmedzenie
              ich použitia; nepotrebuje vaše osobné údaje na účely spracúvania,
              ale potrebuje ich vy na preukázanie, uplatňovanie alebo
              obhajovanie nárokov; namietate spracúvanie osobných údajov podľa §
              27 ods. 1, a to až do overenia, či oprávnené dôvody na strane
              prevádzkovateľa prevažujú nad oprávnenými dôvodmi dotknutej osoby.
            </li>
            <li style={{ listStyle: "disc" }} className="mb-3">
              Ak je spracúvanie osobných údajov založené na oprávnenom záujme
              ambulancie (napr. kamerové systémy), máte právo namietať
              spracúvanie osobných údajov, ktoré sa vás týkajú. Ak ambulancia
              nepreukáže legitímny oprávnený záujem, nebude tieto osobné údaje
              ďalej spracúvať.
            </li>
            <li style={{ listStyle: "disc" }} className="mb-3">
              Ambulancia je povinná zachovávať mlčanlivosť o všetkých údajoch
              týkajúcich sa vášho zdravotného stavu a o skutočnostiach, ktoré sa
              dozvedeli počas vašho ošetrenia. Okrem prípadov, ak je
              zdravotnícky pracovník zbavený mlčanlivosti alebo ak zákon
              prikazuje poskytovať údaje.
            </li>
            <li style={{ listStyle: "disc" }} className="mb-3">
              Ak sa domnievate, že dochádza k neoprávnenému spracúvaniu vašich
              osobných údajov alebo došlo k zneužitiu vašich osobných údajov
              môžete Úradu na ochranu osobných údajov podať návrh na začatie
              konania o ochrane osobných údajov.
            </li>
            <li className="mb-3">
              Korešpondenčná adresa: Úrad na ochranu osobných údajov, Hraničná
              12, 820 07 Bratislava 27, Slovenská republika alebo emailová
              adresa: statny.dozor@pdp.gov.sk.
            </li>
          </ul>
        </div>
      </section>
    </>
  );
};
