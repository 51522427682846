import { Helmet } from "react-helmet";

export const Error404 = () => {
  return (
    <>
      <Helmet>
        <title>404 | M E D I C s.r.o.</title>
      </Helmet>
      <section className="error-page section">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 offset-lg-3 col-12">
              <div className="error-inner">
                <h1>
                  404<span>Ľutujeme, táto stránka neexistuje!</span>
                </h1>
                <p>
                  Odkaz je nefunkčný alebo stránka bola odstránená. Skúste sa
                  vrátiť na predchádzajúcu stránku.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
