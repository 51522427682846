export const ostrostData = [
  {
    question: 'Čo je zraková ostrosť?',
    answer:
      "Znamená to rozlišovaciu schopnosť oka. \
    Dobré videnie je totiž v dnešnej dobe veľmi dôležité a problémy so zrakom netreba odkladať, ale riešiť.\
    <br/> Vyšetrenie zrakovej ostrosti je základné vyšetrenie očí na zistenie, či oči nemajú tzv. refrakčnú poruchu, kedy oko nedokáže zaostriť na vzdialený alebo blízky bod. \
    <br/> <br/> V prípade refrakčnej poruchy vidí pacient neostro: \
    <ul> \
<li style='list-style:disc'>pri krátkozrakosti vidí neostro do diaľky (dioptrie mínus)</li>\
<li style='list-style:disc'>pri ďalekozrakosti zasa vidí rozmazane blízke predmety, napríklad vidí neostro pri čítaní (dioptrie plus)</li>\
</ul>",
  },
  {
    question: 'Ako sa vyšetruje zrakvová ostrosť?',
    answer:
      'Orientačne vieme vyšetriť zrakovú ostrosť na diaľku osobitne pre každé oko na ambulancii pomocou Snellenovho optotypu.\
    Snellenov optotyp je zostavený z písmen  alebo znakov rôznej veľkosti, ktoré sú zoradené od najväčšieho po najmenšie. Vyšetrovacia vzdialenosť je 5 metrov, to je pre oko tzv. praktické nekonečno. \
    <br/>  Hodnota zrakovej ostrosti je určovaná pomocou zlomku. Čitateľ určuje vzdialenosť pacienta od optotypu a menovateľ číslo riadku, ktoré pacient prečíta alebo rozozná. \
    <br/>  Najlepšia zraková ostrosť je udávaná v zlomkovom zápise 5 / 5.\
    <br/>  U pacientov nad 40 rokov vyšetrujeme aj potrebu korekcie poruchy zraku na blízko  pomocou textu na čítanie do blízka - čítajú sa texty rôznej veľkosti zo vzdialenosti 30cm. Pokiaľ je zrak v poriadku, prečíta riadok č. 1.\
    <br/>  Podrobné vyšetrenie zrakovej ostrosti môžte absolvovať u očného lekára alebo v očnej optike.\
    ',
  },
];
