export const tetanusData = [
  {
    question: "Aká je to choroba?",
    answer:
      "Ochorenie dostalo názov podľa známeho klinického prejavu tohto ochorenia, a to tŕpnutie šije. Ochorenie sa pri plnom vedomí chorého prejavuje sťahujúcimi kŕčmi žuvacieho svalstva (tzv. trizmus), šijového svalstva a neskôr celého trupu. V ďalšom priebehu u chorého dochádza ku krčom celého tela. \
    Ochorenie vyvoláva jed, ktorý produkuje baktéria Clostridium tetani. Do organizmu sa dostáva po poranení kože. Tento bacil sa dostáva do prostredia s výlučkami rôznych zvierat (kone, ošípané, ovce, hovädzí dobytok). Zostáva v pôde niekoľko rokov, kde je schopný prežiť vďaka tomu, že sa obalí na tzv. spóry.\
    Po vniknutí do organizmu sa premení za vhodných podmienok na životaschopný mikrób, pričom vhodné podmienky sú určené najmä hlbšími ranami, ktoré sa zatvoria a práve nedostatok kyslíka je pre množenie tohto mikroorganizmu veľmi priaznivé. \
    Inkubačný čas, teda doba od vniknutia bacila do organizmu po prepuknutie prvých príznakov ochorenia trvá 7-14 dní. \
    Ochorenia prebiehajú ťažko a približne 50% chorých umiera. \
    Z človeka na človeka sa neprenáša.",
  },
  {
    question: "Je možné toto ochorenie liečiť?",
    answer:
      "Pri včasnej diagnóze je liekom prvej voľby podanie antitetanického séra alebo ľudského gamaglobulínu s vysokým obsahom antitoxínových protilátok, dôkladné chirurgické ošetrenie rany, podávanie penicilínu a podanie jednej dávky očkovacej látky ako stimul pre tvorbu vlastných protilátok.",
  },
  {
    question: "Vyskytuje sa ochorenie na Slovensku a vo svete? ",
    answer:
      "Vďaka zavedenému pravidelnému očkovaniu proti tetanu výskyt ochorení významne poklesol a v súčasnosti sa ochorenie vyskytuje len zriedkavo. Posledné 2 prípady boli zaznamenané v roku 2002, bohužiaľ oba končili smrťou.\
    Tetanus je ochorenie s celosvetovým výskytom. V krajinách, kde zaočkovanosť obyvateľstva je nízka, sa tetanus vyskytuje často, a to aj v takej závažnej podobe, ako je novorodenecký tetanus, ktorý sa vyskytuje najmä v krajinách s nedostatočnou zdravotnou starostlivosťou a vykonávaním pôrodov v nehygienických podmienkach. ",
  },
  {
    question: "Ako predchádzať ochoreniu na tetanus?",
    answer:
      "Účinnou prevenciou je očkovanie proti tetanu, ktoré vzhľadom na charakter očkovacej látky treba opakovať aj v dospelosti. Súčasťou predchádzaniu ochorenia na tetanus je aj správne ošetrovanie rán z hľadiska profylaxie tetanu. Preto je dôležité, aby občania poznali aspoň približný dátum posledného očkovania proti tetanu, pretože od tohto dátumu sa odvíja postup pri ošetrovaní poranení v závislosti od veľkosti a hĺbky poranenia, ako aj veku poraneného. Očkovanie proti tetanu je na Slovensku povinné.",
  },
  {
    question: "Je očkovanie bezpečné? ",
    answer:
      "V súčasnosti sa na Slovensku dospelé osoby preočkovávajú kombinovanou vakcínou proti tetanu a záškrtu, je možne aj kombinácia proti čiernemu kašľu.\
    Očkovanie býva sprevádzané malými lokálnymi príznakmi, ako sú bolestivosť v mieste vpichu, začervenanie a opuch, ktoré však vo väčšine prípadov ustupujú.",
  },
  {
    question: "Kedy sa očkuje?",
    answer:
      "Základné očkovanie sa vykonáva ako súčasť očkovania proti záškrtu, čiernemu kašľu, vírusovej hepatitíde B, hemofilovým invazívnym infekciám a detskej obrne, teda po ukončení 9. týždňa života dojčaťa 3 dávkami. \
    Prvá dávka sa podá v treťom mesiaci života dojčaťa, druhá dávka o 2 mesiace a tretia dávka o 6 mesiacov po druhej dávke. \
    Preočkovanie sa vykonáva v 6. a 13. roku života dieťaťa a potom každých 10-15 rokov.   \
    Prvé preočkovanie dospelých je vo veku 30 rokov, potom každých 15 rokov.",
  },
];
