export const fobData = [
  {
    question: 'Čo je FOB test?',
    answer:
      'FOB test je test na okultné - skryté krvácanie, a teda na zistenie prítomnosti krvi v stolici, kotlů nedokážete postrehnúť voľným okom.',
  },
  {
    question: 'Ako prebieha FOB test?',
    answer:
      'Test na okultné krvácanie je rýchly, presný a jednoduchý, môžete si ho urobiť kedykoľvek a kdekoľvek počas dňa. Neovplyvnia ho ani potraviny, ako napríklad červené mäso či zelenina. Nie je nutná ani diéta či testovanie nalačno.\
      Obdržiíe špeiálnu skúmavku s návodom na odber vzorky stolice. \
      Vzorku môžete dať analyzovať hneď po odbere, ale môžete ju aj uskladniť vo vzduchotesnej uzatvorenej skúmavke  v chladničke a odniesť ju otestovať neskôr. ',
  },
  {
    question: 'Čo dokáže FOB odhaliť?',
    answer:
      'Pozitívny test na okultné, a teda skryté krvácania v tráviacom trakte,  môže spôsobovať niekoľko závažných ochorení, ktoré sa inak ani nemusia prejaviť.\
    Niekotré z nich sú uvedené nižšie, ale príčin môže byť oveľa viac. <br/> <br/> Kolorektálny karcinóm- rakovina hrubého čreva je, našťastie, dobre liečiteľná, ak sa zachytí včas, čo nám pomôže zistit ja test FOB.  Medzi prejavy tejto choroby patrí napríklad hnačka či zápcha, zmena vzhľadu stolice, plynatosť, nafúknuté brucho, kŕče, úbytok hmotnosti, chudokrvnosť a už spomínaná krv v stolici.\
    <br/> Divertikulitída je vlastne zápal čreva, ktorý postihuje až 10 percent ľudí na Slovensku vo veku nad 40 rokov. U starších ľudí sa vyskytuje u viac ako polovice. Príčinou vzniku divertikulitídy je nesprávna strava či skôr nedostatok vlákniny v strave. Choroba vzniká tak, že sa na čreve vytvoria akési vydutinky - divertikle, najmä na hrubom čreve. Keď sa tieto miesta zapália, vzniká divertikulitída.\
     <br/> Polypy sú malé útvary, ktoré bývajú pologuľovité stopkaté či prisadlé. Na začiatku ochorenia nemusíte pociťovať žiadne problémy či bolesť a okrem krvi v stolici nemá táto choroba ani žiadne príznaky. Ak však polyp narastie, môže zabraňovať priechodu stravy v črevách. Niektoré polypy sa môžu časom meniť na rakovinové.\
     <br/> Crohnova choroba postihuje zápalom ľubovoľnú časť celého tráviace traktu, a teda od úst až po konečník. Najčastejšie je však na častiach hrubého či tenkého čreva. Prečo táto choroba vzniká sa ešte nevie, ale vedci predpokladajú, že je to akási neprimeraná reakcia na nejakú potravinu či baktérie v tráviacom trakte. Taktiež tu svoju rolu zohrávajú aj dedičné faktory. Medzi príznaky tejto choroby radíme bolesti brucha, ktoré pretrvávajú dlhšie a pripomínajú zápal slepého čreva, nevoľnosť, úbytok na váhe, zväčšenie lymfatických uzlín, teplota, chudokrvnosť.\
     <br/> Hemoroidy /zlatá žila/ sú tiež častou príčinou krvacania z konečníka. Hemoroidy sú zhluky ciev (žíl) v konečníku, umiestnené v jeho najnižšej oblasti. Tieto žily môžu spôsobiť problém len v prípade, že sa začnú',
  },
];
