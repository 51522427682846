export const glykemiaData = [
  {
    question: "Čo je to glykémia?",
    answer:
      "Koncentrácia glukózy v krvi sa odborne nazýva glykémia. Vyjadruje sa v chemických jednotkách milimol na liter (mmol/l).",
  },
  {
    question: "Aké sú normy?",
    answer:
      "U ľudí bez cukrovky sa glykémia nalačno pohybuje v rozmedzí 3,3 mmol/l až 5,5 mmol/l. Krátko po jedle môže byť hodnota glykémie vyššia, ale do dvoch hodín klesne pod 7,8 mmol/l.\
    Potvrdená (t.j. opakovane zistená) hodnota glukózy nalačno 7 mmol/l alebo vyššia može znamenat diagnózu cukrovky. Pri vyšetrení, ktoré nie je vykonané nalačno, je pre potvrdenie diagnózy stanovená hodnota glukózy v plazme 11 mmol/l alebo vyššia. ",
  },
  {
    question: "Čo je to glukozový tolerančný test (oGTT)?",
    answer:
      "Je to test, pri ktorom sa meria glykémia nalačo a po záťaži cukrom. \
    Stanovuje sa predovšetkým na vylúčenie alebo potvrdenie cukrovky.",
  },
  {
    question: "Ako prebieha oGTT?",
    answer:
      "Test sa robí ráno nalačno po 8-hodinovom lačnení, v časovom rozsahu 2 hodiny. Odoberie sa krv zo žily. Potom vyšetrovaná osoba vypije v priebehu 3 – 5 minút 75 g glukózy rozpustenej v 300 ml vody /presne prirpavený roztok dostupný v lekárni/. Ďalší odber krvi sa robí 60 a 120 minút po vypití glukózového roztoku.",
  },
  {
    question: "Ako sa pripraviť na oGTT test? ",
    answer:
      "Deň pred vyšetrením jedzte a robte aktivity ako obyčajne.\
    Nesnažte sa meniť svoju životosprávu, neobmedzujte príjem cukrov, rovnako nepodávajte extrémne fyzické výkony. Tie by mohli skresliť výsledky testov. Dbajte o to, aby ste počas celého času testu zostali sedieť. Fyzická aktivita by totiž skreslila výsledky testu, ktorý by potom mohol byť „falošne“ v normále.",
  },
  {
    question: "Ako sa hodnotí?",
    answer:
      "<div><span>Ak je po 120 minútach nameraná hladina:</span><br/><br/>\
    <ul>\
    <li style='list-style:disc'>nižšia ako 7,8 mmol/l, pacient má normálnu glukózovú toleranciu a nemá diabetes,</li>\
    <li style='list-style:disc'>avyššia ako 7,8 mmol/l, ale nižšia ako 11,1 mmol/l, ide o porušenú glukózovú toleranciu</li>\
    <li style='list-style:disc'> nad 11,1 mmol/l hovoríme už o diabete/li>\
     </ul>",
  },
];
