export const BreadCrumbs = ({ page, starostlivost }: any) => {
  return (
    <div className="breadcrumbs overlay">
      <div className="container">
        <div className="bread-inner">
          <div className="row">
            <div className="col-12">
              <h2>{page}</h2>
              <ul className="bread-list">
                <li>
                  <a href="/">Domov</a>
                </li>
                <li>
                  <i className="icofont-simple-right"></i>
                </li>
                {starostlivost && (
                  <>
                    <li className="active">
                      <a href="/zdravotna-starostlivost">Zdravotná starostlivosť</a>
                    </li>
                    <li>
                      <i className="icofont-simple-right"></i>
                    </li>
                  </>
                )}
                <li className="active">{page}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
