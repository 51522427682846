import { Helmet } from 'react-helmet';
import { BreadCrumbs } from '../components/Global';

export const OrdinacnyCas = () => {
  return (
    <>
      <Helmet>
        <title>Ordinačné hodiny | M E D I C s.r.o.</title>
      </Helmet>
      <BreadCrumbs page="Ordinačné hodiny" />
      <section className="doctor-calendar-area section">
        <p className="container mb-3">
          Celkové ordinačné hodiny sú celkový čas našej práce, ktorý zahřňa okrem osobného vyšetrenia pacienta aj
          poskytovanie zdravotnej starostlivosti ako napr. hodnotenie výsledkov, telefonická a mailová komunikácia s
          pacientami, administratívne úkonym vystavovanie výpisov a potvrdení pre pacientov. Počas ordinačného času
          poskytujeme priamu osobnú zdravotnú starostlivosť.
        </p>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="doctor-calendar-table table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Lekár</th>
                      <th>Pondelok</th>
                      <th>Utorok</th>
                      <th>Streda</th>
                      <th>Štvrtok</th>
                      <th>Piatok</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td>
                        <span>MUDr. Tomková</span>
                      </td>
                      <td>
                        <h3>6:15 - 12:15</h3>
                        <h3>13:00 - 15:00</h3>
                      </td>
                      <td>
                        <h3>6:15 - 12:15</h3>
                      </td>
                      <td>
                        <h3>6:15 - 12:15</h3>
                        <h3>13:00 - 17:00</h3>
                      </td>
                      <td>
                        <h3>6:15 - 11:15</h3>
                      </td>
                      <td>
                        <h3>6:15 - 12:15</h3>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <span>MUDr. Tomko</span>
                      </td>
                      <td>
                        <h3>6:30 - 12:30</h3>
                        <h3>13:00 - 15:00</h3>
                      </td>
                      <td>
                        <h3>6:30 - 12:30</h3>
                      </td>
                      <td>
                        <h3>6:30 - 12:30</h3>
                        <h3>13:00 - 17:00</h3>
                      </td>
                      <td>
                        <h3>6:30 - 11:30</h3>
                      </td>
                      <td>
                        <h3>6:30 - 12:30</h3>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
