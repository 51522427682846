export const vyplachdata = [
  {
    question: 'Čo je výplach úší?',
    answer:
      'Ucho upchaté mazom je jedným z najbežnejších problémov, s akým sa lekári v oblasti uší stretávajú. Ide o stav, keď sa maz dostane tak hlboko, že zaplní celú šírku ušného kanálika. Zväčša nejde o silne bolestivý problém, no zhoršuje komfort človeka a oslabuje sluch. Ušný maz je pritom dôležitou súčasťou nášho tela. Chráni načúvací orgán pred vonkajšími vplyvmi, ako je prach či drobné nečistoty, a zabraňuje množeniu baktérií.',
  },
  {
    question: 'Aké sú príčiny vzniku mazovej zátky?',
    answer:
      "<ul> \
    <li style='list-style:disc'>nadprodukcia mazu</li>\
    <li style='list-style:disc'>porucha transportu mazu smerom von zo zvukovodu</li>\
    <li style='list-style:disc'>nedokonalá hygiena – každodenné čistenie uší vatovými tyčinkami dráždi kožu zvukovodu</li>\
     </ul>",
  },
  {
    question: 'Aké sú príznaky mazovej zátky?',
    answer:
      "<ul> \
  <li style='list-style:disc'>porucha sluchu</li>\
  <li style='list-style:disc'>hučanie v ušiach</li>\
  <li style='list-style:disc'>Najčastejšie sa príznaky objavia po kúpaní alebo sprchovaní, po zatlačení prstom alebo vatovou tyčinkou pri čistení – maz sa prilepí na blanu bubienka.</li>\
   </ul>",
  },
  {
    question: 'Ako sa diagnostikuje mazová zátka?',
    answer:
      'Prevádza sa otoskopické vyšetrenie - lekár sa pozrie do zvukovodu malým ušným nástrojom- otoskopom. Vyšetrenie nie je bolestivé.',
  },
  {
    question: 'Ako sa lieči mazová zátka?',
    answer:
      'Výplachuje sa  vlažnou vodou.  Na zmäkčenie tvrdého mazu je vhodné zopár dní pred výplachom kvapkať do ucha peroxid vodíka.   ',
  },
];
