export const zdravotnyData = [
  {
    question: 'Čo je to zdravotný preukaz?',
    answer:
      'Podľa vyhlášky 585/2008 epidemiologicky závažnú činnosť môže vykonávať len osoba odborne a zdravotne spôsobilá, čo sa preukazuje zdravotným preukazom.<br/><br/>\
    Zdravotný preukaz, nazývaný aj preukaz na prácu s potravinami alebo potravinársky preukaz, sa vyžaduje v zamestnaniach, kde môže prípadný bacilonosič ohroziť zákazníkov alebo tovar, s ktorým  prichádza do styku, napr.  v hypermarketoch, reštauráciách, potravinách, pri práci s deťmi a pod.<br/><br/>\
    Vystavuje ho všeobecný lekár.<br/>\
    Vystavenie preukazu je spoplatnené.',
  },
  {
    question: 'Čo potrebujem absolvovať?',
    answer:
      'Je potrebné absolvovať základné lekárske vyšetrenie, odbery a výtery na vylúčenie bacilonosičstva.<br/><br/>\
    Zároveň je potrebné absolvovať  poučenie o právach a povinnostiach, potrebe informovať lekára pri určitých zdravotných ťažkostiach. vid tlačivo vo formulároch. ',
  },
  {
    question: 'Povinnosti držiteľa zdravotného preukazu',
    answer:
      "<ul>\
    <li style='list-style:disc'>Mať preukaz pri sebe a predložiť ho súčasne s občianskym preukazom na výzvu kontrolných orgánov. </li>\
    <li style='list-style:disc'>Zachovávať všetky zdravotnícke pokyny o hygiene a osobne čistote a o správnom zaobchádzaní s požívatinami</li>\
    <li style='list-style:disc'>Pri každom lekárskom ošetrení je osoba, ktorá vykonáva epidemiologicky závažnú činnosť, povinná upozorniť lekára na druh vykonávanej epidemiologicky závažnej činnosti. Tieto skutočnosti zaznačí lekár do jej zdravotnej dokumentácie. </li>\
    <li style='list-style:disc'>Bezpodmienečne a bezodkladne hlásiť zamestnávateľovi nákazlivé, alebo horúčkovité ochorenia svoje alebo člena svojej domácnosti a podrobiť sa    ihneď lekárskej kontrole a dodržiavať všetky zdravotnícke opatrenia    nariadené lekárom. </li>\
    <li style='list-style:disc'>Pri vlastnom ochorení, alebo príslušníka domácnosti hlásiť ošetrujúcemu lekárovi vykonávanie epidemiologicky závažnej činnosti a predložiť tento preukaz.</li>\
    <li style='list-style:disc'>Držiteľ bol poučený o svojich právach a povinnostiach a o tom, že pri ochorení musí vyhľadať lekára. </li>\
    </ul>",
  },
];
