import { useState } from 'react';
import { Helmet } from 'react-helmet';
import { BreadCrumbs } from '../components/Global';

export const Service = ({ title, faqData, top = '', bottom = '' }: any) => {
  const [isOpen, setIsOpen] = useState<any>({});
  return (
    <>
      <Helmet>
        <title>{title} | M E D I C s.r.o.</title>
      </Helmet>
      <BreadCrumbs starostlivost page={title} />
      <section className="faq-area section">
        <div className="container">
          <div className="row faq-wrap">
            <div className="col-lg-12">
              <p className="pb-5">{top}</p>
              <div className="faq-item">
                <ul className="accordion">
                  {faqData.map((item: any, index: any) => (
                    <li className="wow fadeInUp" data-wow-delay=".3s">
                      <a
                        className={isOpen[index] ? 'active' : ''}
                        onClick={() => setIsOpen({ ...isOpen, [index]: !isOpen[index] })}
                      >
                        {item.question}
                      </a>
                      <p
                        style={{ display: isOpen[index] ? 'block' : 'none' }}
                        dangerouslySetInnerHTML={{ __html: item.answer }}
                      ></p>
                    </li>
                  ))}
                </ul>
              </div>
              <p className="pt-5">{bottom}</p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
