import { Helmet } from "react-helmet";
import { Insurance } from "../components/Global";
import { Care, Info, Slider } from "../components/Home";

export const Home = () => {
  return (
    <>
      <Helmet>
        <title>Domov | M E D I C s.r.o.</title>
      </Helmet>
      <Slider />
      <Info />
      <Care />
      <Insurance />
      {/* <About />
      <References />
      <Departments />
      <HomePricing />
      <News />
      <ContactForm /> */}
    </>
  );
};
