import { Helmet } from "react-helmet";
import { BreadCrumbs } from "../components/Global";
import { Team } from "../components/Personal/Team";

export const Personal = () => {
  return (
    <>
      <Helmet>
        <title>Personál | M E D I C s.r.o.</title>
      </Helmet>
      <BreadCrumbs page="Personál" />
      <Team />
    </>
  );
};
