export const ekgAbiData = [
  {
    question: "Čo je to EKG?",
    answer:
      "Elektrokardiografia - je základná vyšetrovacia, neinvazívna metóda v kardiológii.  Ako EKG môže byť tiež označovaný záznam ale aj samotný lekársky prístroj, ktorý pomocou elektród umiestnených na tele pacienta meria elektrickú aktivitu srdca a zaznamenáva ju v podobe krivky -EKG. ",
  },
  {
    question: "Čo vie EKG odhaliť? ",
    answer:
      "EKG je významnou diagnostickou metódou pri určovaní chorôb srdca- akútnych i chronických  poškodení srdca /napr. infarkt myokardu, arytmie/, ale môže  odhaliť aj iné poruchy /napr. poruchy minerálov, štítnej žľazy, pľuc.",
  },
  {
    question: "Ako EKG prebieha?",
    answer:
      "Vyšetrenie je bezbolestné a prebieha jednoducho. Pacient je  vyzvaný, aby si vyzliekol oblečenie. Obnažený musí byť hrudník, zápästia a nohy nad členkami. Počas merania je nevyhnutné, aby pacient ležal na chrbte vo vodorovnej polohe. Mal by dýchať pokojne. Na hruď a končatiny sa nanesie vodivý gél. Na kožu pacienta sa umiestnia elektródy a tie následne zachytávajú elektrické prúdy šíriace sa zo srdca, to sa prenáša a zapisuje vo forme EKG krivky. ",
  },
  {
    question: "Čo je to ABI?",
    answer:
      "Ide o vyšetrenie špeciálnym prístrojom, ktorý meria krvný tlak na oboch horných a dolných končatinách. Prístroj výsledky zhodnotí a získa sa tak informácia o stave tepien. ",
  },
  {
    question: "Čo vie ABI odhaliť?",
    answer:
      "Vypovedá o stave krvného zásobenia dolných končatín tepnami, ale  tým vlastne napoveda aj o stave tepien celeho tela.<br/><br/> \
      Vypočita tzv. ABI INDEX - je definovaný ako pomer krvného tlaku meraného na dolnej končatine (členku) a krvného tlaku meraného na hornej končatine (ramene). Normalne hodnoty sú 1,00-1,40 \
Zároveň sa výpočtom stanovuje rýchlosť pulznej vlny, čiže informácia o tuhosti tepien, normálna hodnota je do 10.<br/><br/> \
Znížený tlak na dolných končatinách sa vyskytuje pri ischemickej chorobe dolných končatín ( upchávanie tepien dolných končatín). Zvýšený napr. u diabetikov. \
ABI index je spoľahlivý marker ischemickej choroby dolných končatín a predpovedá pravdepodobnosť výskytu srdcového infarktu alebo cievnej mozgovej príhody.",
  },
  {
    question: "Kedy sa ABI vyšetruje? ",
    answer:
      "<ul>\
    <li style='list-style:disc'>u pacientov s podozrením na nedokrvenie dolných končatín-   s bolesťami dolných končatín, s klaudikáciami alebo pokojovými bolesťami</li>\
    <li style='list-style:disc'>u pacientov nad 60 rokov alebo mladšch s prítomnosťou rizikových faktorov</li>\
    <li style='list-style:disc'>u fajčiarov nad 50 rokov</li>\
    <li style='list-style:disc'>u pacientov s aterosklerózou</li>\
    <li style='list-style:disc'>u pacientov s poruchami metabolizmu tukov</li>\
    <li style='list-style:disc'>u diabetikov </li>\
    <li style='list-style:disc'>u hypertonikov</li>\
     </ul>",
  },
];
